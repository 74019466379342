import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/service/auth.service";
import { RestClientService } from "src/app/core/service/rest-client.service";
import { UtilitiesService } from "src/app/core/service/utilities.service";
import Swal from "sweetalert2";
import * as _ from "lodash";
import { enumStatut } from "src/app/core/enumerator/enumerator";

@Component({
  selector: 'app-historique-livraison-aux-services',
  templateUrl: './historique-livraison-aux-services.component.html',
  styleUrls: ['./historique-livraison-aux-services.component.scss']
})
export class HistoriqueLivraisonAuxServicesComponent implements OnInit {

  items: Array<any> = [];
  itemToSave: any = {};
  modalRef: BsModalRef;
  user: any = {};
  itemToSearch: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  busyGet: Subscription;
  busySave: Subscription;
  listCaisier: Array<any> = [];
  medicaments: Array<any> = [];
  statutEnum : any = {};
  loading: boolean = false;
  itemsMedicament: Array<any> = [];
  itemsUniteFonctionnelle : any = [];

  @Input() isLivraisonAuService : boolean = false;
  @Input() confirmReception : boolean = false;

  constructor(
    private authService: AuthService,
    private restClient: RestClientService,
    private modalService: BsModalService,
    private utilities: UtilitiesService
  ) {
    this.user = this.authService.currentUserValue;  
    this.statutEnum = enumStatut;
  }


  changePaginationSize($event) {
    if($event) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt($event);
    }
    this.getItems();
  }
  

  getItems(uniteFocntId ?) {

    let request = {
      user: this.user.id,
      data: {
        libelle: this.itemToSearch.libelle,
        uniteFoncId: this.itemToSearch.uniteFoncId || null
        // gestMatDemandeServiceId: this.itemToSearch.uniteFoncId || null
        // uniteFoncId : this.itemToSearch.uniteFoncId || null
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage,
    };

    this.busyGet = this.restClient
      .post("gestMatLivraison/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.items = res["items"];
            this.totalItems = res["count"];
          } else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        (err) => {}
      );
  }

  confirmReceptionArticle(item) {
   
    Swal.fire({
      title: "Reception de produit",
      text: "Vous-êtes sur le point de confirmer une recption. Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.saveReception(item);
      }
    });
  }

  saveReception(item?) {

    this.loading = true;
    var request = {
      user: this.user.id,
      datas: [{id : item?.id}],
    };
    
    this.busyGet = this.restClient.post('gestMatLivraison/receptionArticles', request).subscribe(
      (res) => {
        this.loading = false;
        if (!res["hasError"]) {
          // if (res["items"] && res["items"].length > 0) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
            this.getItems();
            
          // }
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.utilities.showNotification(
              "snackbar-danger",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
          }
        }
      },
      (err) => {
        this.utilities.showNotification(
          "snackbar-danger",
          this.utilities.getMessageEndPointNotAvailble(),
          "bottom",
          "center"
        );
        this.loading = false;
      }
    );
  }

  onToggle(it) {
    let tempon = it.isDetailsOpen;
    this.items.forEach((element) => {
      element.isDetailsOpen = false;
    });
    it.isDetailsOpen = !tempon;
    if (it.isDetailsOpen) {
      this.itemsMedicament = it.medicaments;
    }
  }

  getUnit() {
    let request = {
      user: this.user.id,
      data: {},
    };
    this.busyGet = this.restClient
      .post("adminUniteFonctionnelle/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.itemsUniteFonctionnelle = _.orderBy(res["items"], ['libelle'], ['asc']);

            // Si nous ne sommes pas a la livraiosn, on gris et on preselectionne l'unité fonctionnelle
            if(!this.isLivraisonAuService) {
               this.itemToSearch.uniteFoncId = this.user?.uniteFoncActiveId;
               this.getItems(!this.isLivraisonAuService ? this.user?.uniteFoncActiveId : null);
            }
          } else {
            this.itemsUniteFonctionnelle = [];
          }
        },
        (err) => {}
      );
  }

  pageChanged(event: any): void {
    this.currentPage = event.page ;
    this.getItems();
  }

  ngOnInit(): void {
    this.getUnit();
    this.getItems();
   
  }


}
