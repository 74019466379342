<div class="" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
    <table class="table table-condensed table-striped ">
        <thead>
            <tr>
                <th colspan="15">
                    <div class="row mt-4 search-part">
                        <div class="col-md-3">
                            <label> Rechercher par n° programme </label>
                            <input [(ngModel)]="itemToSearch.libelle" class="form-control custom-input pull-left"
                                placeholder="Rechercher n° Programme ..." (keyup)="currentPage = 1;getItems()" />
                        </div>

                        <div class="col-md-2 col-sm-12 mb-2">
                            <label> Date de séance </label>
                            <input class="form-control custom-input" placeholder="Disponibilité" autocomplete="off"
                                [(ngModel)]="itemToSearch.dateDispo" name="dialyseDisponibiliteLibelle" type="text"
                                bsDatepicker (bsValueChange)="getItems($event)"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-dark-blue' }">
                        </div>

                    </div>
                </th>
            </tr>
            <tr class="custom-table-head">

                <th class="txt-align-center">
                    <a (click)="currentPage = 1;getItems()" title="Actualiser"> <i
                            class="fa fa-redo-alt"></i></a>&nbsp;&nbsp;
                    &nbsp;&nbsp;#
                </th>
                <th>N°Requête</th>
                <th>Code séance</th>
                <th>Disponibilité</th>
                <th>Matricule</th>
                <th>Patient</th>
                <th class="txt-align-center">Matin</th>
                <th class="txt-align-center">Soir</th>
                <th>Medecin</th>
                <!-- <th>Enregistre le </th> -->
                <th class="txt-align-center">Statut</th>
                <th class="txt-align-center">Actions</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of items; let indice = index">

                <td class="txt-align-center"> {{getExactlyNumberRow(this.currentPage,indice)}}</td>
                <td>{{item?.dataProgramme?.dataRequete?.numeroRequete}}</td>
                <td>{{item?.code}}</td>
                <td>{{item?.dialyseDisponibiliteLibelle}}</td>
                <td>{{item?.dataProgramme?.dataRequete?.dataBeneficiaire?.matriculeBeneficiaire}}</td>
                <td>{{item?.dataProgramme?.dataRequete?.dataBeneficiaire?.nom +'
                    '+item?.dataProgramme?.dataRequete?.dataBeneficiaire?.prenom}}</td>

                <td class="txt-align-center"> <i class="fa fa-circle"
                        *ngIf="item?.dataProgramme?.heureDebutMatin && item?.dataProgramme?.heureFinMatin"
                        style="color:rgb(103, 195, 103)"></i></td>
                <td class="txt-align-center"> <i class="fa fa-circle"
                        *ngIf="(item?.dataProgramme?.heureDebutSoir && item?.dataProgramme?.heureFinSoir)"
                        style="color:rgb(103, 195, 103)"></i> </td>
                <td>{{item?.nomPrenomMedecin}}</td>
                <!-- <td>{{item?.createdAt}}</td> -->
                <!-- <td>{{ (item?.enregistrePar || item?.enregistrerPar) | uppercase }}</td> -->
                <td class="txt-align-center">
                    <span *ngIf="item?.isTraite" class="badge badge-success retour-pas-encore-effectue">Effectué</span>
                    <span *ngIf="!item?.isTraite" class="retour-pas-encore-effectue"
                        class="badge badge-danger retour-effectue"
                        [class.expired]="itemToSearch.differenceDate > 0 || item?.motifSeance">
                        {{ (itemToSearch.differenceDate > 0 && !item?.motifSeance) ? 'Expiré' : (item?.motifSeance ?
                        'Absence' : 'Pas encore effectué') }} </span>
                </td>

                <td class="txt-align-center">

                    <div ngbDropdown class="d-inline-block" *ngIf="!item?.isTraite">
                        <button type="button" class="btn btn-custom-action-dropdown" id="action"
                            ngbDropdownToggle>Actions</button>
                        <div ngbDropdownMenu aria-labelledby="action"
                            class="dropdown-validation-plainte">

                            <button ngbDropdownItem 
                            (click)="openModal(item,templateDialyse,true)" *ngIf="!item?.isTraite && !item?.motifSeance"> &nbsp;
                            Nouvel accueil 
                            </button>

                            <button ngbDropdownItem 
                            (click)="openModal(item,templateAbsence)"
                            *ngIf="!item?.isTraite && !item?.motifSeance && itemToSearch.differenceDate >= 0"> &nbsp;
                            Absence 
                           </button>

                            <button ngbDropdownItem (click)="openModal(item,templateAbsence)" *ngIf="item?.motifSeance" >
                            Motif absence
                            </button>

                        </div>
                    </div>

                </td>
            </tr>

            <tr *ngIf="items && items.length > 0">
                <td colspan="15">
                    <div class="row pagination-part">
                        <div class="col-md-7" style="text-align: center">
                            <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                                [(ngModel)]="currentPage" class="pagination-sm" previousText="&lsaquo;"
                                nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                                (pageChanged)="pageChanged($event)"></pagination>
                        </div>

                        <div class="col-md-5">
                            <div class="paginator-per-page">

                                <app-element-by-page-paginator (paginationChange)="changePaginationSize($event)"
                                    [defaultItemPerPage]="itemsPerPage">
                                </app-element-by-page-paginator>

                                <span class="span_total_entree pull-right">Total : {{
                                    totalItems
                                    }}</span>

                            </div>
                        </div>
                    </div>
                </td>
            </tr>

            <tr *ngIf="!items || items.length == 0">
                <td colspan="13">
                    <div class="col-md-12 txt-align-center">
                        Aucune données disponible
                    </div>
                </td>
            </tr>

        </tbody>


    </table>
</div>



<!-- Enregistrer un accueil de dialise -->
<ng-template #templateDialyse>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            Accueil patient dialyse
        </h4>

        <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>

    </div>
    <div class="modal-body">
        <div>
            <app-form-reception-patient-dialyse [currentSeance]="itemToSave" (closeForm)="fermertureFormulaire()">
            </app-form-reception-patient-dialyse>
        </div>
    </div>
</ng-template>


<ng-template #templateAbsence>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            Absence de séance
        </h4>

        <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-12 mb-3">
                <label>Motif d'absence : <span class="required_champs">*</span></label>
                <textarea rows="3" class="form-control custom-input" name="motifSeance" [disabled]="isAbsenceView"
                    [(ngModel)]="itemToSave.motifSeance" placeholder="Motif d'absence">
                </textarea>
            </div>
        </div>
    </div>

    <div class="modal-footer modal-footer-custom">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <button type="button" (click)="confirmSaveItem(itemToSave)" class="btn btn-md custom_btn_primary pull-right"
                [disabled]="!itemToSave.libelle" [class.auth-spinner]="loading" [disabled]="loading" *ngIf="!isAbsenceView">
                Enregistrer
            </button>

            <button type="button" class="btn btn-md btn-cancel pull-right" (click)="modalRef.hide()">
                Annuler
            </button>
        </div>
    </div>

</ng-template>