import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import * as moment from 'moment';

@Component({
  selector: 'app-form-reception-patient-dialyse',
  templateUrl: './form-reception-patient-dialyse.component.html',
  styleUrls: ['./form-reception-patient-dialyse.component.scss']
})
export class FormReceptionPatientDialyseComponent implements OnInit {

  itemToSave : any = {};
  ListeSeanceProgramme : Array<any> = [];
  listAssurancesToSave :  any = [];
  ListePatientAssruance :  Array<any> = [];
  listeUniteFonctionnelle : any = [];
  listActes : Array<any> = [];
  listAssurances : any = [];
  user : any = {};
  loading : boolean = false;
  busySave : Subscription;
  busyGet: Subscription;
  currentPatient : any = {};
  @Output() closeForm = new EventEmitter<any>();
  @Input() currentSeance : any;
  currentPatientInAccueilDialyse : any = {};
  listActesToSave : any = [];
  
  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
  }

  itemPatientSelected($event) {
    if($event) {
      this.currentPatientInAccueilDialyse = {...$event};
      if($event?.assurances) {
        
        this.ListePatientAssruance = $event?.assurances;
        this.listAssurancesToSave = $event?.assurances;
      }
    }
  }


  addActes() {

    console.log(" this.itemToSave?.acteSelected ",this.itemToSave?.acteSelected);
    
    if (!this.itemToSave?.acteSelected) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un acte !",
        "bottom",
        "center");
      return;
    }

    // Verifier si ce acte n'existe pas dans le tableau
    let isExisteActe = _.find(this.listActesToSave, (o) => { return o.id == this.itemToSave?.acteSelected?.id });

    if (isExisteActe) {
      // Dans ce cas l'acte est deja dans le tableau
      this.utilities.showNotification("snackbar-danger", "Cet acte a déjà été sélectionné !",
        "bottom",
        "center");
      return;
    }

    this.itemToSave.acteSelected.prixUnitaire = this.itemToSave.acteSelected.cout;
    this.itemToSave.acteSelected.quantiteActe = 1;
    this.itemToSave.acteSelected.id = this.itemToSave?.acteSelected?.acteId;

    this.listActesToSave.push(this?.itemToSave?.acteSelected);

    this.itemToSave.acteSelected = "";
    this.getTotalFacture();
    // this.getMontantPartAssurance();
  }

  deleteItemActe(indice) {
    Swal.fire({
      title: "Suppression d'acte",
      text: "Confirmez-vous l'action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.listActesToSave.splice(indice, 1);
        this.getTotalFacture();
      }
    });
  }

  getTotalFacture(){
    let montantTotal = _.sumBy(this.listActesToSave, (o) => { return o.prixUnitaire * (o.quantiteActe || 0); });
    this.itemToSave.totalFactureActe = montantTotal;

    return this.itemToSave.totalFactureActe;
  }

  // getMontantPartAssurance(currentTaux ?,assurance ?){

  //   if(currentTaux && (currentTaux < 0 || currentTaux > 100 )){
  //     this.utilities.showNotification("snackbar-danger", "Veuillez renseigner un taux valide !",
  //     "bottom",
  //     "center");

  //     currentTaux = 0;
  //     assurance.taux = 0;
  //     return;
  //   }

  //   let montantTotalPartAssurance = 0;

  //   console.log(" montantTotalPartAssurance ",montantTotalPartAssurance);
    
  //   if(this.listAssurancesToSave && this.listAssurancesToSave.length > 0){
  //     montantTotalPartAssurance = this.getTotalFacture();

  //     // On parcourt le tableau et pour chaque item
  //     this.listAssurancesToSave.forEach(assurance => {
  //       if(assurance.taux && assurance.taux >= 0){
  //         assurance.partAssurance = Math.round(montantTotalPartAssurance * (parseFloat(assurance.taux) / 100));
  //         montantTotalPartAssurance = montantTotalPartAssurance - assurance.partAssurance;
  //       }
  //     });
  //   }
  // }

  getActes() {
    let request = {
      user: this.user.id,
      data: {
        typeActeId: this.itemToSave?.typeActeSelected?.typeActeId,
        uniteFonctId : this.itemToSave?.uniteFonctionnelleSelected?.id || null,
        categorieClientId: this.currentSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.categorieClientId
      }
    }

    this.busyGet = this.restClient.post('adminTarificationActe/getByCriteria', request)
      .subscribe(
        res => {
          console.log(" res ",res);
          
          if (res && res['items']) {
            this.listActes = _.orderBy(res['items'], ['adminActeLibelle'], ['asc']); 
          }
          else {
            this.listActes = [];
          }
        },
        err => {
        }
      );
  }

  getUniteFonctionnelle() {
    let request = {
      user: this.user.id,
      data: {
        // typeActeId: this.itemToSave?.typeActeSelected?.typeActeId,
        // categorieClientId: this.currentSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.categorieClientId
      }
    }

    this.busyGet = this.restClient.post('adminUniteFonctionnelle/getByCriteria', request)
      .subscribe(
        res => {
          console.log(" adminUniteFonctionnelle ",res);
          
          if (res && res['items']) {
            this.listeUniteFonctionnelle = _.orderBy(res['items'], ['libelle'], ['asc']); 
          }
          else {
            this.listeUniteFonctionnelle = [];
          }
        },
        err => {
        }
      );
  }


  addAssurance(assurance?: any) {

    if (!this.itemToSave?.assuranceSelected && !assurance) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner une assurance !",
        "bottom",
        "center");
      return;
    }

    // Verifier si ce acte n'existe pas dans le tableau
    let isExisteAssurance = _.find(this.listAssurancesToSave, (o) => { return (o.id == this.itemToSave?.assuranceSelected?.id || o.id == assurance?.id) });

    if (isExisteAssurance) {
      // Dans ce cas l'acte est deja dans le tableau
      this.utilities.showNotification("snackbar-danger", "Cette assurance a déjà été sélectionnée !",
        "bottom",
        "center");
      return;
    }

    // if(assurance) {
    //   this.itemToSave.assuranceSelected = {...assurance};

    //   this.itemToSave.assuranceSelected.taux = this.itemPatient.tauxPc;
    // }

    this.itemToSave.assuranceSelected['ordre'] = (this.listAssurancesToSave && this.listAssurancesToSave.length > 1) ? this.listAssurancesToSave.length - 1 : 0;

    this.listAssurancesToSave.push(this?.itemToSave?.assuranceSelected || assurance);
    this.itemToSave.assuranceSelected = "";


    console.log(" this.listAssurancesToSave ",this.listAssurancesToSave);
    
  }

  deleteItemAssurance(indice) {
    Swal.fire({
      title: "Suppression d'assurance",
      text: "Confirmez-vous l'action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.listAssurancesToSave.splice(indice, 1);
        // this.getMontantTotalAssurance();
      }
    });
  }

  getAssurance() {
    let request = {
      user: this.user.id,
      data: {
      }
    }

    this.busyGet = this.restClient.post('adminAssurance/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listAssurances =  _.orderBy(res['items'], ['raisonSociale'], ['asc']);
          }
          else {
            this.listAssurances = [];
          }
        },
        err => {
        }
      );
  }

  confirmSaveItem(item) {

      let objToSave = {
        dialyseProgrammeSeanceId : this.currentSeance?.id,
        observation : this.itemToSave.observation,
        datasAssurances : this.listAssurancesToSave,
        datasActe : this.listActesToSave || null
      };
  
      Swal.fire({
        title: "Accueil dialyse",
        text:  "Vous êtes sur le point d'enregistrer un accueil de dialyse. Voulez-vous poursuivre cette action ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0665aa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then((result) => {
        if (result.value) {
          this.saveItem(objToSave);
        }
      });
    
  }

  saveItem(item) {
    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      datas: [itemAEnregistrer],
    };

    this.busySave = this.restClient
      .post("dialyseAccueil/create",
        request
      )
      .subscribe(
        (res) => {
          console.log("resul", res);
          this.loading = false;

          if (!res["hasError"]) {
            if (res["items"] && res["items"].length > 0) {
              this.utilities.showNotification(
                "snackbar-success",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );

              this.closeFormulaire();
            }
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
          this.loading = false;
        }
      );
  }

  closeFormulaire() {
    this.closeForm.emit(true);
  }

  ngOnInit(): void {
    // this.getSeanceProgramme();
    this.getAssurance();
    // this.getActes();
    this.getUniteFonctionnelle();
    console.log(" currentPatient ",this.currentSeance); 
    
  }

}
