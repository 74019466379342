import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as moment from 'moment';
import * as _ from "lodash";
import { enumStatut, enumTypeRetour } from 'src/app/core/enumerator/enumerator';

@Component({
  selector: 'app-historique-retour',
  templateUrl: './historique-retour.component.html',
  styleUrls: ['./historique-retour.component.scss']
})
export class HistoriqueRetourComponent implements OnInit {

  listItems: Array<any> = [];
  currentMedicament : any = {};
  itemsMedicament : any = [];
  montantTotal : any;
  refreshValue : any;
  items: Array<any> = [];
  itemToSave: any = {};
  modalRef: BsModalRef;
  user: any = {};
  itemToSearch: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalItems: number;
  busyGet: Subscription;
  busySave: Subscription;
  loading: boolean = false;
  endPoint : string = 'pharmacieRetour/';
  patientId: any;
  itemsPharmacieMagasin: any;
  datasPrescription: any=[];
  listeMedicamentAVendre : any [];
  magasin: any={};
  typeRetourEnumm : any = {};
  statutEnum : any = {};
  
  @Input() typeRetour : any;

  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
    this.typeRetourEnumm = enumTypeRetour;
    this.statutEnum = enumStatut;
  }

  pageChanged(event: any): void {
    this.currentPage = event.page ;
    this.getItems();
  }

  changePaginationSize($event) {
    if($event) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt($event);
    }
    this.getItems();
  }
  

  confirmMiseAJourRetour(item) {

    let obj = {
      id : item?.id,
      venteDirecteId : null,
      venteHospiId : null,
      venteActeExterneId : null
    }

    // Rattacher l'id de la vente 
    if(this.typeRetour == enumTypeRetour.VENTE_ACTE_EXTERNES) {
      if(!item.retourActesExternes || item.retourActesExternes.length == 0) {
        this.utilities.showNotification(
          "snackbar-danger","Impossible d'annuler car les lignes de retour n'ont pas été enregistrés pour ce retour !",
          "bottom",
          "center"
        );
        return
      }
      obj.venteActeExterneId = item.retourActesExternes[0]?.venteActeExterneId;
    }

    if(this.typeRetour == enumTypeRetour.VENTE_HOSPI || this.typeRetour == enumTypeRetour.VENTE_HOSPI_BANQUE_SANG) {
      if(!item.retourVenteHospi || item.retourVenteHospi.length == 0) {
        this.utilities.showNotification(
          "snackbar-danger","Impossible d'annuler car les lignes de retour n'ont pas été enregistrés pour ce retour !",
          "bottom",
          "center"
        );
        return
      }
      obj.venteHospiId = item.retourVenteHospi[0]?.venteHospiId;
    }

    if(this.typeRetour == enumTypeRetour.VENTE_DIRECTE) {
      if(!item.retourVentesDirectes || item.retourVentesDirectes.length == 0) {
        this.utilities.showNotification(
          "snackbar-danger","Impossible d'annuler car les lignes de retour n'ont pas été enregistrés pour ce retour !",
          "bottom",
          "center"
        );
        return
      }
      obj.venteDirecteId = item.retourVentesDirectes[0]?.venteDirecteId;
    }


    Swal.fire({
      title: "Mise à jour stock",
      text: "Vous êtes sur le point de mettre à jour le stock . Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.miseAJourQuantite(obj);
      }
    });
  }

  confirmEnvoyerEnValidation(item) {
    Swal.fire({
      title: "Envoi en validation",
      text: "Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.sendInValidation(item);
      }
    });
  }

  sendInValidation(item) {
   
   this.loading = true;
    
    var request = {
      user: this.user.id,
      data:  {id : item.id}
    }
   
    this.busyGet = this.restClient.post('pharmacieRetour/envoyerEnValidation', request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            // if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
              this.getItems();
            // }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }
  
  miseAJourQuantite(item) {

    this.loading = true;
    
    var request = {
      user: this.user.id,
      datas: [
        item
      ]
    }

    this.busyGet = this.restClient.post('pharmacieRetour/updateStockRetour', request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
              this.getItems();
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }
  
  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = (page - 1) * 10 + (index + 1);
    }
    return num;
  }


  confirmCancelRetour(item) {
    Swal.fire({
      title: "Annulation retour",
      text: "Vous êtes sur le point d'annuler un retour'. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3f51b5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {

        let obj = {
          id : item?.id,
          statut : enumStatut.ANNULE,
          venteDirecteId : null,
          venteHospiId : null,
          venteActeExterneId : null
        }

        // Rattacher l'id de la vente 
        if(this.typeRetour == enumTypeRetour.VENTE_ACTE_EXTERNES) {
          if(!item.retourActesExternes || item.retourActesExternes.length == 0) {
            this.utilities.showNotification(
              "snackbar-danger","Impossible d'annuler car les lignes de retour n'ont pas été enregistrés pour ce retour !",
              "bottom",
              "center"
            );
            return
          }
          obj.venteActeExterneId = item.retourActesExternes[0]?.venteActeExterneId;
        }

        if(this.typeRetour == enumTypeRetour.VENTE_HOSPI || this.typeRetour == enumTypeRetour.VENTE_HOSPI_BANQUE_SANG) {
          if(!item.retourVenteHospi || item.retourVenteHospi.length == 0) {
            this.utilities.showNotification(
              "snackbar-danger","Impossible d'annuler car les lignes de retour n'ont pas été enregistrés pour ce retour !",
              "bottom",
              "center"
            );
            return
          }
          obj.venteHospiId = item.retourVenteHospi[0]?.venteHospiId;
        }

        if(this.typeRetour == enumTypeRetour.VENTE_DIRECTE) {
          if(!item.retourVentesDirectes || item.retourVentesDirectes.length == 0) {
            this.utilities.showNotification(
              "snackbar-danger","Impossible d'annuler car les lignes de retour n'ont pas été enregistrés pour ce retour !",
              "bottom",
              "center"
            );
            return
          }
          obj.venteDirecteId = item.retourVentesDirectes[0]?.venteDirecteId;
        }

        this.cancelRetour(obj);
      }
    });
  }

  cancelRetour(obj) {
    var request = {
      user: this.user.id,
      datas: [obj],
    };
    this.busyGet = this.restClient
      .post("pharmacieRetour/update", request)
      .subscribe(
        (res) => {
          console.log(res);
          if (!res["hasError"]) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
            this.currentPage = 1;
            this.getItems();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }

 
  getItems() {
    let request = {
      user: this.user.id,
      data: {
        libelle: this.itemToSearch.libelle ? this.itemToSearch.libelle : null,
        typeRetour : this.typeRetour
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage
    }

    this.busyGet = this.restClient.post( this.endPoint+'/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.items = res['items'];
            this.totalItems = res['count'];
          }
          else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        err => {
        }
      );
  }

  onToggle(it){
    let tempon = it.isDetailsOpen
    this.items.forEach(element => {
      element.isDetailsOpen = false
    });
    it.isDetailsOpen =! tempon
    if(it.isDetailsOpen){
      this.itemsMedicament = it.medicaments
    }
  } 

  getTotal(it?){

      let montant = _.sumBy(this.listeMedicamentAVendre, (o) => { return (o.prixUnitaireVente * o?.quantite) });
      this.montantTotal = montant;
  
      return montant;
  }

  ngOnInit() {
    this.getItems();
  }

}
