<div class="modal-header custom-modal-header"
    [ngBusy]="{busy: busySave, message: 'Veuillez patienter ...', backdrop: false}">
    <!-- <div class="modal-header custom-modal-header"> -->
    <h4 class="modal-title pull-left">Programme patient dialyse </h4>

    <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>


</div>
<form (ngSubmit)="confirmSaveItem()">
    <div class="modal-body">


        <div class="row">
            <div class="col-md-12 col-sm-12">
                <fieldset  class="custom-fieldset">
                    <legend> Information requête </legend>

                    <div class="row">

                        <div class="col-md-3 col-sm-12" *ngIf="!hasDejaRequete">
                            <div class="form-group">
                                <label>Réquête de dialyse:
                                    <span class="required_champs">*</span>
                                </label>
                                <ng-select [(ngModel)]="itemToSave.requeteSelected" 
                                    placeholder="Choisissez uen requête" name="requ" id="requ">
                                    <ng-option *ngFor="let requete of ListeRequeteDialyse" [value]="requete">{{ requete?.numeroRequete |
                                        uppercase }} ({{requete?.dataBeneficiaire?.nom + ' '+requete?.dataBeneficiaire?.prenom }})
                                    </ng-option> 
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-12" *ngIf="hasDejaRequete">
                            <div class="form-group">
                                <label>Réquête de dialyse:
                                    <span class="required_champs">*</span>
                                </label>

                                <input class="form-control" autocomplete="off" 
                                [(ngModel)]="itemToSave.requestSelected.numeroRequete" name="numRe" type="text" disabled>

                            </div>
                        </div>
                        
                        <div class="col-md-9 col-sm-12" *ngIf="(itemToSave.requeteSelected && itemToSave.requeteSelected.id > 0) || beneficiaireId">
                            <app-info-patient-consultation
                            [idPatient]="itemToSave.requeteSelected?.dataBeneficiaire?.id || beneficiaireId"
                            [withAssurances]="false" >
                        </app-info-patient-consultation>
                        </div>
                    </div>

                </fieldset>
            </div>

            <div class="col-md-12 mt-3">

                <fieldset  class="custom-fieldset">
                    <legend> Programme et médecin </legend>

                    <div class="row">
                        <div class="col-md-4 col-sm-12">
                            <div class="form-group">
                                <label>Agent:
                                    <span class="required_champs">*</span>
                                </label>
                                <ng-select [(ngModel)]="medecinId" bindValue="id" placeholder="Choisissez l'agent" name="medecin"
                                    id="medecin" [multiple]="false" [closeOnSelect]="true">
                                    <ng-option *ngFor="let medecin of listeMedecin" [value]="medecin.userId">{{
                                        medecin.userNom + "
                                        " + medecin.userPrenom
                                        | uppercase
                                        }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
            
                        <div class="col-md-4 col-sm-12">
                            <div class="form-group">
                                <label>Jour(s) de disponibilité:
                                    <span class="required_champs">*</span>
                                </label>
                                <ng-select [(ngModel)]="agendaDays" [closeOnSelect]="false" 
                                    placeholder="Choisissez le(s) jour(s) de disponibilité" [multiple]="true" name="agendaDays"
                                    id="agendaDays">
                                    <ng-option *ngFor="let day of days" [value]="day">{{
                                        day?.name
                                        }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
            
                        <div class="col-md-4 col-sm-12">
                            <div class="form-group">
                                <label for="periode">Période</label>
                                <input type="text" class="form-control text-center" placeholder="Période de disponibilité"
                                    name="periode" bsDaterangepicker readonly [bsConfig]="{
                              dateInputFormat: 'DD/MM/YYYY',
                              containerClass: 'theme-dark-blue'
                            }" [(ngModel)]="periodeDisponibilite" />
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-12">
                            <fieldset class="custom-fieldset">
                                <legend>Heure de disponibilité</legend>
            
                                <div class="row">
                                    <div class="col-6 cadre-dispo">
                                        Matin &nbsp;&nbsp;<input type="checkbox" name="dispoMatin" [(ngModel)]="dispoMatin" />
            
                                        <div class="col-12" *ngIf="dispoMatin">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="heure-part">
                                                       
                                                        <ng-select [(ngModel)]="itemMatin.heureDebut" [closeOnSelect]="true" (change)="logDay()"
                                                            placeholder="Heure" [multiple]="false" name="heureDebMat"
                                                            id="heureDebMat">
                                                            <ng-option *ngFor="let hr of listeHeuresMatin" [value]="hr">{{ hr }}</ng-option>
                                                        </ng-select>
            
                                                        <span> : </span>
            
                                                        <ng-select [(ngModel)]="itemMatin.minuteDebut" [closeOnSelect]="true" (change)="logDay()"
                                                            placeholder="Minute" [multiple]="false" name="minuteMatin"
                                                            id="minuteMatin">
                                                            <ng-option *ngFor="let hr of listeMinutes" [value]="hr">{{ hr }}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="heure-part">
                                                       
                                                        <ng-select [(ngModel)]="itemMatin.heureFin" [closeOnSelect]="true" (change)="logDay()"
                                                            placeholder="Heure" [multiple]="false" name="heureFin"
                                                            id="heureFin">
                                                            <ng-option *ngFor="let hr of listeHeuresMatin" [value]="hr">{{ hr }}</ng-option>
                                                        </ng-select>
            
                                                        <span> : </span>
            
                                                        <ng-select [(ngModel)]="itemMatin.minuteFin" [closeOnSelect]="true" (change)="logDay()"
                                                            placeholder="Minute" [multiple]="false" name="minuteFin"
                                                            id="minuteFin">
                                                            <ng-option *ngFor="let hr of listeMinutes" [value]="hr">{{ hr }}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="col-6 cadre-dispo">
                                        Soir &nbsp;&nbsp;<input type="checkbox" name="dispoSoir" [(ngModel)]="dispoSoir" />
            
                                        <div class="col-12" *ngIf="dispoSoir">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="heure-part">
                                                       
                                                        <ng-select [(ngModel)]="itemSoir.heureDebut" [closeOnSelect]="true"
                                                            placeholder="Heure" [multiple]="false" name="heureSoir"
                                                            id="heureSoir">
                                                            <ng-option *ngFor="let hr of listeHeuresSoir" [value]="hr">{{ hr }}</ng-option>
                                                        </ng-select>
            
                                                        <span> : </span>
            
                                                        <ng-select [(ngModel)]="itemSoir.minuteDebut" [closeOnSelect]="true" 
                                                            placeholder="Minute" [multiple]="false" name="minuteSoir"
                                                            id="minuteSoir">
                                                            <ng-option *ngFor="let hr of listeMinutes" [value]="hr">{{ hr }}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="heure-part">
                                                       
                                                        <ng-select [(ngModel)]="itemSoir.heureFin" [closeOnSelect]="true"
                                                            placeholder="Heure" [multiple]="false" name="heureFin"
                                                            id="heureFin">
                                                            <ng-option *ngFor="let hr of listeHeuresSoir" [value]="hr">{{ hr }}</ng-option>
                                                        </ng-select>
            
                                                        <span> : </span>
            
                                                        <ng-select [(ngModel)]="itemSoir.minuteFin" [closeOnSelect]="true" 
                                                            placeholder="Minute" [multiple]="false" name="minuteFin"
                                                            id="minuteFin">
                                                            <ng-option *ngFor="let hr of listeMinutes" [value]="hr">{{ hr }}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
            
                                </div>
                            </fieldset>
                        </div>
            
                    </div>



                </fieldset>


            </div>
        </div>

        
    </div>

    <div class="modal-footer modal-footer-custom">


        <button type="button" class="btn btn-md btn-cancel pull-right" (click)="modalRef.hide()">
            Annuler
        </button>

        <button type="submit" class="btn btn-md custom_btn_primary pull-right">
            Enregistrer
        </button>

    </div>
</form>