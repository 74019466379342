<div class=""  [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
    <table class="table table-condensed table-hover table-striped ">
        <thead>
            <tr>
                <th colspan="12">
                    <div class="row mt-4 search-part">
                        <div class="col-md-3">
                            <input [(ngModel)]="itemToSearch.libelle"
                                class="form-control custom-input pull-left"
                                placeholder="Rechercher ..." (keyup)="currentPage = 1;getItems()" />
                        </div>
                       
                    </div>
                </th>
            </tr>
            <tr class="custom-table-head">
             
                <th class="txt-align-center">
                    <a (click)="currentPage = 1;getItems()" title="Actualiser"> <i class="fa fa-redo-alt"></i></a>&nbsp;&nbsp;
                    &nbsp;&nbsp;#
                </th>
                <th>N°Requête</th>
                <th>N°Programme</th>
                <th>Jour de dispo</th>
                <th>Matin</th>
                <th>Soir</th>
                <!-- <th>Medecin</th> -->
                <th>Matricule</th>
                <th>Patient</th>
                <th>Etape circuit</th>
                <th>Enregistre le </th>
                <th>Enregistré par</th>
                <th class="txt-align-center"></th>
            </tr>
        </thead>

        <tbody >
            <tr  *ngFor="let item of items; let indice = index" [class.hasObservation]="item?.observation">
                
                <td class="txt-align-center">  {{getExactlyNumberRow(this.currentPage,indice)}}</td>
                <td>{{item?.code}}</td>
                <td>{{item?.createdAt}}</td>
                <td>{{item?.dataProgrammeSeance?.dialyseDisponibiliteLibelle}}</td>
                <!-- <td>{{item?.dataProgrammeSeance?.dataProgramme?.heureDebutMatin +' - '+item?.dataProgrammeSeance?.dataProgramme?.heureFinMatin }}</td>
                <td>{{item?.dataProgrammeSeance?.dataProgramme?.heureDebutSoir +' - '+item?.dataProgrammeSeance?.dataProgramme?.heureFinSoir }}</td> -->
                <!-- <td>{{item?.userNom +' '+item?.userPrenom}}</td> -->
               
                <td class="txt-align-center"> <i class="fa fa-circle"
                    *ngIf="item?.dataProgrammeSeance?.dataProgramme?.heureDebutMatin && item?.dataProgrammeSeance?.dataProgramme?.heureFinMatin"
                    style="color:rgb(103, 195, 103)"></i></td>
            <td class="txt-align-center"> <i class="fa fa-circle"
                    *ngIf="(item?.dataProgrammeSeance?.dataProgramme?.heureDebutSoir && item?.dataProgrammeSeance?.dataProgramme?.heureFinSoir)"
                    style="color:rgb(103, 195, 103)"></i> </td>
               
                <td>{{item?.dataProgrammeSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.matriculeBeneficiaire}}</td>
                <td>{{item?.dataProgrammeSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.nom +' - '+item?.dataProgrammeSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.prenom }}</td>
                <td>{{item?.etapeCircuit}}</td>
                <td>{{item?.createdAt}}</td>
                <td>{{ (item?.enregistrerPar || item?.enregistrePar) | uppercase}}</td>
                <td>
                    <button type="button" class="btn btn-default btn-details"
                        popover="{{item?.observation}}"
                        popoverTitle="Motif"
                        placement="auto" *ngIf="item?.observation" [outsideClick]="true">
                        Observation
                        </button>

                    {{!item?.observation ? ' - ' : ''}}
                </td>
               
            </tr>

            <tr *ngIf="items && items.length > 0">
                <td colspan="12">
                    <div class="row pagination-part">
                        <div class="col-md-9" style="text-align: center">
                            <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                                [(ngModel)]="currentPage" class="pagination-sm" previousText="&lsaquo;"
                                nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                                (pageChanged)="pageChanged($event)"></pagination>
                        </div>

                        <div class="col-md-3">
                            <div class="paginator-per-page">
              
                              <app-element-by-page-paginator (paginationChange)="changePaginationSize($event)"
                                [defaultItemPerPage]="itemsPerPage">
                              </app-element-by-page-paginator>
              
                              <span class="span_total_entree pull-right">Total : {{
                                totalItems
                                }}</span>
              
                            </div>
                          </div>
                    </div>
                </td>
            </tr> 

            <tr *ngIf="!items || items.length == 0">
                <td colspan="12">
                    <div class="col-md-12 txt-align-center">
                        Aucune données disponible
                    </div>
                </td>
            </tr>

        </tbody>

      
    </table>
</div>
                     