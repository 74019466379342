import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-envoyer-caisse',
  templateUrl: './form-envoyer-caisse.component.html',
  styleUrls: ['./form-envoyer-caisse.component.scss']
})
export class FormEnvoyerCaisseComponent implements OnInit {

  @Input() consultation : any;
  @Input() isEnvoiALaCaisseFromAccueil : boolean = false;
  @Input() itemAccueil : any;
  @Input() itemBulletin : any;
  @Input() actes : any;
  @Input() typeActes : any;
  @Input() isEnvoiALaCaisseOdonto : boolean;
  @Input() codeUniteFonctionnelle : string;
  @Input() isHistoConsultation: any;
  @Output() closeFormEnvoiCaisse = new EventEmitter<any>();

  busyGet: Subscription;
  user : any = {};
  listMedecins : Array<any> = [];
  itemMontantVente : any = {};
  listeUniteFonctionnelle : Array<any> = [];
  listAssurancesToSave :  Array<any> = [];
  listActesToSave : Array<any> = [];
  itemToSave : any = {};

  constructor(private restClient: RestClientService,private authService: AuthService, private utilities: UtilitiesService ) {
    this.user = this.authService.currentUserValue;
  }

  closeFormModal() {
    this.closeFormEnvoiCaisse.emit(true);
  }

  getUniteFonctionnelle() {
    let request = {
      user: this.user.id,
      data: {
        // code : this.codeUniteFonctionnelle || null
      }
    }

    this.busyGet = this.restClient.post('adminUniteFonctionnelle/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listeUniteFonctionnelle = _.orderBy(res['items'], ['libelle'], ['asc']);

            console.log(" this.codeUniteFonctionnelle ",this.codeUniteFonctionnelle);
            
            if(this.codeUniteFonctionnelle && this.codeUniteFonctionnelle.length > 0) {
              this.itemToSave.uniteFonctionnelleSelected = _.find(this.listeUniteFonctionnelle, (o) => { return o.code?.toLowerCase() === this.codeUniteFonctionnelle?.toLowerCase()  })?.id || null;
              this.getMedecin()
            }
           
          }
          else {
            this.listeUniteFonctionnelle = [];
          }
        },
        err => {
        }
      );
  }

  getMedecin() {
    let request = {
      user: this.user.id,
      data: {
        // isMedecin: true 
        uniteFonctionnelleId : this.itemToSave.uniteFonctionnelleSelected
      }
    }

    this.busyGet = this.restClient.post('user/getMedecins', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            console.log(" utilisateur ",res);
            
            this.listMedecins = _.orderBy(res['items'], ['nom'], ['asc']); 
          }
          else {
            this.listMedecins = [];
          }
        },
        err => {
        }
      );
  }

  getTotalFacture(){
    let montantTotal = _.sumBy(this.listActesToSave, (o) => { return parseFloat(o.prixUnitaire)*parseFloat(o.quantite); });
    this.itemToSave.totalFactureActe = montantTotal;

    return this.itemToSave.totalFactureActe;
  }

  // getMontantTotalAssurance(){
  //   let montantTotalPartAssurance = 0;
    
  //   if(this.listAssurancesToSave){
  //     montantTotalPartAssurance = _.sumBy(this.listAssurancesToSave, (o) => { return o.partAssurance; });
  //   }
   
  //   return montantTotalPartAssurance;
  // }


  getTotalPartPatient(){

    let totalDepense = this.getTotalFacture();
    let montantActesPrisEnCharge = 0;
    let montantNonPrisEnCharge = totalDepense;

    let listeActesPrisEnCharge = _.filter(this.listActesToSave, (o) => { return o.isPc; });

    if(listeActesPrisEnCharge && listeActesPrisEnCharge.length > 0) {
      montantActesPrisEnCharge = _.sumBy(listeActesPrisEnCharge, (o) => { return parseFloat(o.prixUnitaire)*parseFloat(o.quantite)});
      montantNonPrisEnCharge = totalDepense - montantActesPrisEnCharge;
    }

    let currentMontantAPayer = 0;
    let totalParAssurance = 0;
    // return this.getTotalDepense()

    // Vertifier  si le patient est assuré
    if(this.listAssurancesToSave) {
      // En ce moment le patient est assuré
      this.listAssurancesToSave.forEach(assurance => {
           currentMontantAPayer = montantActesPrisEnCharge;
          if(assurance && assurance?.taux) {
            currentMontantAPayer = currentMontantAPayer - ( currentMontantAPayer * assurance?.taux/ 100);
          }
      });

      totalParAssurance = montantActesPrisEnCharge - currentMontantAPayer;

      this.itemMontantVente = {
        total : totalDepense,
        partPatient : currentMontantAPayer + (montantNonPrisEnCharge),
        partAssurance : totalParAssurance
      }
    }else {
      // Le patient paye plaain pot
      this.itemMontantVente = {
        total : totalDepense,
        partPatient : totalDepense,
        partAssurance : 0
      }
    }
  }


  confirmSaveItem() {

    if (!this.itemAccueil) {
      this.utilities.showNotification("snackbar-danger", "Aucun accueil n'a été selectionné !",
        "bottom",
        "center");
      return;
    }

    if (!this.actes || this.actes.length == 0) {
      this.utilities.showNotification("snackbar-danger", "Aucun acte sélectionné !",
        "bottom",
        "center");
      return;
    }

    if (!this.consultation) {
      this.utilities.showNotification("snackbar-danger", "Aucune consultation n'a été enregistré !",
        "bottom",
        "center");
      return;
    }

    if(!this.itemToSave.uniteFonctionnelleSelected && !this.isEnvoiALaCaisseOdonto) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner l'unité fonctionnelle !",
      "bottom",
      "center");
      return;
    }

    if(!this.itemToSave.medecinSelectedId && !this.isEnvoiALaCaisseOdonto) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le medecin !",
      "bottom",
      "center");
      return;
    }

    let requestEnvoiALaCaisse = {

          patientId : this.consultation?.patientId || this.itemAccueil.patientId,
          categoriePatientId : this.itemAccueil?.categoriePatientId,
          uniteFoncId : this.isEnvoiALaCaisseOdonto ? this.user?.uniteFoncActiveId : this.itemToSave.uniteFonctionnelleSelected,
          praticienId : this.isEnvoiALaCaisseOdonto ? this.user?.id : this.itemToSave.medecinSelectedId,
          typeActeId : this.typeActes?.id,
          datasActe : this.listActesToSave || [],
          datasAssurances : this.listAssurancesToSave || [],
          partAssurance : this.itemMontantVente.partAssurance || 0,
          partPatient : this.itemMontantVente.partPatient, //(this.itemToSave.totalFactureActe - (this.getMontantTotalAssurance() || 0 )),
          consultationId : this.consultation?.consultationId || this.consultation?.id,
          codeActe: this.typeActes.code,
          montant: this.itemToSave.totalFactureActe,
          bulletinConsultationId : this.itemBulletin?.id || null
    }
    

    Swal.fire({
      title: "Envoi à la caisse",
      text: "Confirmez-vous l'action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.sendInCaisse(requestEnvoiALaCaisse);
      }
    });
  }


  sendInCaisse(item) {

    let itemAEnregistrer = Object.assign({}, item);

    itemAEnregistrer.isOdontoSendCaisse = this.isEnvoiALaCaisseOdonto;
     
    var request = {
      user: this.user.id,
      datas: [itemAEnregistrer],
    };
    
    this.busyGet = this.restClient
      .post( "accueil/createForCaisseInRoute", request)
      .subscribe(
        (res) => {
          console.log("resul", res);

          if (!res["hasError"]) {
            if (res["items"] && res["items"].length > 0) {
              this.utilities.showNotification(
                "snackbar-success",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );

              this.closeFormModal();
            }
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {

    console.log("changes",changes);
    
    if(changes['isEnvoiALaCaisseFromAccueil'] && changes['isEnvoiALaCaisseFromAccueil'].currentValue) {
       this.codeUniteFonctionnelle = this.typeActes.code;
    }

  }

  ngOnInit(): void {
    // console.log(" this.consultation ",this.consultation);
    // console.log(" itemAccueil ",this.itemAccueil);
    // console.log(" actes ",this.actes);
    // console.log(" typeActes ",this.typeActes);

    this.listActesToSave = this.actes;
 
    if(this.itemAccueil?.assurances && this.itemAccueil?.assurances.length > 0) {
      this.listAssurancesToSave = this.itemAccueil?.assurances;
      this.getTotalPartPatient();
    }

    this.getTotalFacture();

    this.getUniteFonctionnelle();
    
  }

}
