import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { enumTypeRupture } from 'src/app/core/enumerator/enumerator';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';

@Component({
  selector: 'app-search-medicament-rupture-stock',
  templateUrl: './search-medicament-rupture-stock.component.html',
  styleUrls: ['./search-medicament-rupture-stock.component.scss']
})
export class SearchMedicamentRuptureStockComponent implements OnInit {

//Declaration de la variable d'emission des valeurs
@Output() itemMedicamentSelected = new EventEmitter<any>();
@Input() cancelItemSelected : any;
@Input() defaultItem : any;
@Input() idMagasin : any;
@Input() disabledItem : boolean = false;
@Input() canCreateRupture : boolean = false;
@Input() hasSearchIdMedcoc : any;
@Input() dateDebut : any;
@Input() dateFin : any;

modalRef: BsModalRef;
isDisabled : boolean = false;
itemToSearch: any = {};
itemToSave: any = {};
listItem: Array<any> = [];
user : any = {};
busyGet: Subscription;
itemSelected : any = {};
canDisplay : boolean = false;

constructor(private authService: AuthService, private restClient: RestClientService,private modalService: BsModalService,private utilities: UtilitiesService) {
  this.user = this.authService.currentUserValue;


}

getItems() {
  // this.listItem=[]
  // On affiche le tableau des patients
  if(this.hasSearchIdMedcoc && !this.idMagasin) {
    this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un magasin !",
    "bottom",
    "center");
    return;
  }

  this.canDisplay = true;

  let request = {
    user: this.user.id,
    dateDebut:this.dateDebut,
    dateFin:this.dateFin,
    data: {
      libelle: this.itemToSearch.searchText ? this.itemToSearch.searchText : null,
      // magasinId : this.idMagasin || null
    },
    index: 0,
    size: 10
  }
    this.busyGet = this.restClient.post('pharmacieMedicament/getMedicamentsCmj', request)
      // this.busyGet = this.restClient.post('pharmacieStockMedicament/getMedocsGrroup', request)
      .subscribe(
        res => {
          // debugger
          if (res && res['items']) {
            this.listItem = res['items'];
            // if(this.listItem && this.listItem.length){
            //   this.listItem.map(li=>{
            //   console.log('listItem: ')
            //   this.addStyleToTyped(li)
            //   })
            // }
            

          }
          else {
            this.listItem = [];
          }
        },
        err => {
        }
      );
  
 
}


openModal(data: any, template: TemplateRef<any>) {

  let config = {backdrop: true, ignoreBackdropClick: true};

  this.itemToSave = {};
  if (data) {
    // Lorsque nous sommes en modification
    this.itemToSave = Object.assign({}, data);
  }

  this.modalRef = this.modalService.show(template,Object.assign({},config, { class: 'modal-lg modal-width-75' }));
  this.modalRef.onHidden
}

selectedItem(item : any){
  if(item){

    console.log(" this.itemSelected ",this.itemSelected);
    
    this.itemSelected = {...item};
    this.itemToSearch.searchText = this.itemSelected.libelle;
    
    // Brodcast de la valeur
    this.itemMedicamentSelected.emit(this.itemSelected);

    // On ferme le tableau de selection des patients
    this.canDisplay = false;
  this.reinitValue()


  }else {
    if(!this.itemToSearch.searchText)  this.itemMedicamentSelected.emit(null);
  }
}

saveItemRupture(item) {
  var request = {
    user: this.user.id,
    datas: [
      item
    ]
  }

  this.restClient.post('pharmacieRuptureStock/createRupture', request)
    .subscribe(
      res => {
        if (!res['hasError']) {
        } 
      },
      err => {
      }
    );
}

reinitValue() {
  this.itemSelected = {};
  this.canDisplay = false;
  this.selectedItem(null);
  this.itemToSearch.searchText = '';
}

patientSaved($event){
  if($event){
    this.selectedItem($event);

    // Après ça on ferme le modal
    this.modalRef.hide();
  }
}

ngOnInit(): void {
  this.canDisplay=false
}


ngOnChanges(changes: SimpleChanges) {

  // this.dateDebut = changes['dateDebut'].currentValue
  // this.dateFin = changes['dateFin'].currentValue

  // if((changes['dateDebut'] && changes['dateDebut'].currentValue)&&(changes['dateFin'] && changes['dateFin'].currentValue))
  // {
  //   this.dateDebut = changes['dateDebut'].currentValue
  //   this.dateFin = changes['dateFin'].currentValue
  //   this.getItems()
  // }
  console.log(" changes ",changes);

  if(changes['dateDebut'] && changes['dateDebut'].currentValue)
  {
    this.dateDebut = changes['dateDebut'].currentValue
  }

  if(changes['dateFin'] && changes['dateFin'].currentValue)
  {
    this.dateFin = changes['dateFin'].currentValue
  }
  console.log('dateDebut: ',this.dateDebut)
  console.log('dateFin: ',this.dateFin)

  this.getItems()


  
  if(changes['cancelItemSelected'] && changes['cancelItemSelected'].currentValue)
  {
    this.itemSelected = {};
    this.itemToSearch.searchText = null;
  }

  if(changes['defaultItem'] && changes['defaultItem'].currentValue)
  {
    this.itemSelected = changes['defaultItem'].currentValue;

    if(changes['defaultItem'].currentValue && changes['defaultItem'].currentValue?.libelle) {
      this.itemToSearch.searchText = changes['defaultItem'].currentValue?.libelle;
    }else {
      this.itemToSearch.searchText = changes['defaultItem'].currentValue;
    }
  }

  if(changes['disabledItem'] && changes['disabledItem'].currentValue)
  {
    this.isDisabled = changes['disabledItem'].currentValue;
  }else {
    this.isDisabled = false;
  }

 

}

addStyleToTyped(li: any) {
  let libelle = li.libelle
  let splited = libelle.split(this.itemToSearch.searchText.toUpperCase())
  console.log('splited: ',splited);
  //RENDRE DYNAMIQUE AU RANG N
  let toReturn = ''
  if(splited.length ==2)
      toReturn +=splited[0]+`<strong>`+this.itemToSearch.searchText.toUpperCase()+`</strong>`+splited[1]
  else{
      for(let i=0;i<splited.length;i++){
          if(splited[i+1])
              toReturn +=splited[i]+`<strong>`+this.itemToSearch.searchText.toUpperCase()+`</strong>`+splited[i+1]
          else
              toReturn +=splited[i]+`<strong>`+this.itemToSearch.searchText.toUpperCase()+`</strong>`
      }
  }
  console.log('toReturn: ',toReturn)
 
  return li.styled = toReturn
}




}
