import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import * as _ from "lodash";

@Component({
  selector: 'app-info-patient-consultation',
  templateUrl: './info-patient-consultation.component.html',
  styleUrls: ['./info-patient-consultation.component.scss']
})
export class InfoPatientConsultationComponent implements OnInit {

  @Input() currentPatient : any;
  @Input() withAssurances : any;
  @Input() listeAssruances : any = [];
  @Input() idPatient  : any;
  @Input() afficheContact  : any;
  @Input() hassNeedGroupSanguin : any;
  @Output() patientSelected: EventEmitter<any> = new EventEmitter();

  busyGet : Subscription;
  imgUrl: any;

  constructor(private restClient: RestClientService) { }


  getPatient() {
    let request = {
      data: {
        id: this.idPatient
      }
    }

    this.busyGet = this.restClient.post('beneficiaire/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.currentPatient = res['items'][0];
            
            console.log(" current patientxxxxx ",this.currentPatient);
            
            this.currentPatient.identiteBeneficiaire = (this.currentPatient.beneficiaireNom || this.currentPatient.nom ) +' '+ (this.currentPatient.beneficiairePrenom || this.currentPatient.prenom);
            this.currentPatient.beneficiaireSexe = this.currentPatient.sexe;
            this.currentPatient.beneficiaireAge = this.currentPatient.age;
            this.currentPatient.categorie = this.currentPatient.adminCategorieClientLibelle;
            this.currentPatient.beneficiaireMatricule = this.currentPatient.matriculeBeneficiaire;
            this.currentPatient.beneficiaireGroupeSanguin = this.currentPatient.adminGroupeSanguinLibelle;

            if(this.listeAssruances && this.listeAssruances.length > 0) {
              this.currentPatient.assurances = this.listeAssruances; 
            }else {
              if(this.currentPatient.dataAssurance) {
                this.currentPatient.dataAssurance.id = this.currentPatient.dataAssurance?.assuranceId;
                this.currentPatient.assurances = [this.currentPatient.dataAssurance];
              }
            }

            this.getInfoBenef();
           

            this.patientSelected.emit(this.currentPatient);
          }
          else {
            this.currentPatient = {};
          }
        },
        err => {
        }
      );
  }


  ngOnChanges(changes: SimpleChanges) {

    console.log(" changes ",changes);
    
    if(changes['currentPatient'] && changes['currentPatient'].currentValue)
    {
      this.currentPatient = changes['currentPatient'].currentValue;
      this.currentPatient.identiteBeneficiaire = this.currentPatient.beneficiaireNom +' '+this.currentPatient.beneficiairePrenom;
      this.currentPatient.categorie =  this.currentPatient.adminCategorieClientLibelle || this.currentPatient.categorieLibelle
      this.currentPatient.contact=this.currentPatient.contact || this.currentPatient.beneficiaireContact
      // Ordonner la liste des assurances
      // console.log('current patient: ',this.currentPatient);
      
      this.currentPatient.assurances = _.orderBy(this.currentPatient.assurances, ['ordre'], ['asc']);
      this.getInfoBenef()
      
    }

    if(changes['idPatient'] && changes['idPatient'].currentValue)
    {
      console.log(" idPatient ",changes['idPatient'].currentValue);

      this.currentPatient = {}
      // On charge le patient afin d'avoir les infos
      this.getPatient();
    }

    console.log(" listeAssruances ",this.listeAssruances);
    
  }

  getInfoBenef(){
    let request = {
      // user: this.user.id,
      data: {
        matriculeBeneficiaire: this.currentPatient.beneficiaireMatricule
     }
    }  

    this.busyGet = this.restClient.post('beneficiaire/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.imgUrl = res['items'][0].imgUrl;
          }
          else {
            this.imgUrl=null
          }
        },
        err => {
        }
      );
  }

  handleError(){
    this.imgUrl="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
  }

  ngOnInit(): void {
  }



}
