import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import * as moment from 'moment';

@Component({
  selector: 'app-form-client',
  templateUrl: './form-client.component.html',
  styleUrls: ['./form-client.component.scss']
})
export class FormClientComponent implements OnInit {

  stepWizard : number = 1;
  loading : boolean = false;
  itemToSave : any = {};
  user : any = {};
  listProfessions : Array<any> = [];
  listGroupesSanguin : Array<any> = [];
  busyGet: Subscription;
  listEthnies : Array<any> = [];
  listAssurances : Array<any> = [];
  listTypeBeneficiaires : Array<any> = [];
  listCategorieClients : Array<any> = [];
  listVilles : Array<any> = [];
  listStatutBeneficiaires : Array<any> = [];
  listCentreImports : Array<any> = [];
  @Output() patientSavedSucces = new EventEmitter<any>();
  @Input() itemPatient : any = {};

  constructor(private authService: AuthService, private restClient: RestClientService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
    this.refreshItem();
  }

  dateActuelle = new Date();

  getProfessions() {
    let request = {
      user: this.user.id,
      data: { }
    }

    this.busyGet = this.restClient.post('adminProfession/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listProfessions = _.orderBy(res['items'], ['libelle'], ['asc']);
            if(this.itemToSave?.id){
              this.itemToSave.professionSelected = _.find(this.listProfessions, (o)=> { return o.id == this.itemToSave?.professionId });
            }
          }
          else {
            this.listProfessions = [];
          }
        },
        err => {
        }
      );
  }

  getAssurances() {
    let request = {
      user: this.user.id,
      data: { }
    }

    this.busyGet = this.restClient.post('adminAssurance/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listAssurances = res['items'];
            if(this.itemToSave?.id){
              this.itemToSave.assuranceSelected = _.find(this.listAssurances, (o)=> { return o.id == this.itemToSave?.assuranceId });
            }
          }
          else {
            this.listAssurances = [];
          }
        },
        err => {
        }
      );
  }

  getCdi() {
    let request = {
      user: this.user.id,
      data: { }
    }

    this.busyGet = this.restClient.post('adminCdi/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listCentreImports = res['items'];

            if(this.itemToSave?.id){
              this.itemToSave.centreDesImpotsSelected = _.find(this.listCentreImports, (o)=> { return o.id == this.itemToSave?.cdiId });
            }
          }
          else {
            this.listCentreImports = [];
          }
        },
        err => {
        }
      );
  }

  getStatutBeneficiaire() {

    let request = {
      user: this.user.id,
      data: { }
    }

    this.busyGet = this.restClient.post('adminStatutBeneficaire/getByCriteria', request)
      .subscribe(
        res => {

          if (res && res['items']) {
            this.listStatutBeneficiaires = res['items'];
          }
          else {
            this.listStatutBeneficiaires = [];
          }
        },
        err => {
        }
      );

  }

  getCategorieClient() {
    let request = {
      user: this.user.id,
      data: { }
    }

    this.busyGet = this.restClient.post('adminCategorieClient/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listCategorieClients = res['items'];
            if(this.itemToSave?.id){
              this.itemToSave.categorieClientSelected = _.find(this.listCategorieClients, (o)=> { return o.id == this.itemToSave?.categorieClientId });
            }
          }
          else {
            this.listCategorieClients = [];
          }
        },
        err => {
        }
      );
  }

  getTypeBeneficiaire() {
    let request = {
      user: this.user.id,
      data: { }
    }

    this.busyGet = this.restClient.post('typeBeneficiaire/getByCriteria', request)
      .subscribe(
        res => {
          console.log("retour de type beneficiaire",res)
          if (res && res['items']) {
            this.listTypeBeneficiaires = res['items'];
            if(this.itemToSave?.id){
              this.itemToSave.typeBeneficiaireSelected = _.find(this.listTypeBeneficiaires, (o)=> { return o.id == this.itemToSave?.typeBeneficiaireId });
            }
          }
          else {
            this.listTypeBeneficiaires = [];
          }
        },
        err => {
        }
      );
  }

  getEthnie() {

    let request = {
      user: this.user.id,
      data: { }
    }

    this.busyGet = this.restClient.post('adminEthnie/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listEthnies = res['items'];
            if(this.itemToSave?.id){
              this.itemToSave.ethnieSelected = _.find(this.listEthnies, (o)=> { return o.id == this.itemToSave?.ethnieId });
            }
          }
          else {
            this.listEthnies = [];
          }
        },
        err => {
        }
      );
  }

  getGroupesSanguins() {
    let request = {
      user: this.user.id,
      data: { }
    }

    this.busyGet = this.restClient.post('adminGroupeSanguin/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listGroupesSanguin = res['items'];
            if(this.itemToSave?.id){
              this.itemToSave.groupeSanguinelected = _.find(this.listGroupesSanguin, (o)=> { return o.id == this.itemToSave?.groupeSanguinId });
            }
          }
          else {
            this.listGroupesSanguin = [];
          }
        },
        err => {
        }
      );
  }

  gotoStepWizard(isNext){
    if(isNext){
      this.stepWizard = this.stepWizard + 1;
    }else{
      this.stepWizard =  this.stepWizard - 1;
    }
  }

  refreshItem(){
    this.getGroupesSanguins();
    this.getEthnie();
    this.getTypeBeneficiaire();
    this.getCategorieClient();
    this.getAssurances();
    this.getProfessions();
    this.getCdi();
    this.getStatutBeneficiaire();
  }

  confirmSaveItem(item) {

    let objToSave = Object.assign({}, item);

    // if (!item || !item.matriculeBeneficiaire) {
    //   this.utilities.showNotification("snackbar-danger", "Veuillez renseigner le matricule !",
    //     "bottom",
    //     "center");
    //   this.stepWizard = 1;
    //   return;
    // }

    if (!item.nom || !item.prenom ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez renseigner le nom et le prénoms !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }

    if (!item.sexe) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le genre du client !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }

    if (!item.dateNais) {
      this.utilities.showNotification("snackbar-danger", "Veuillez renseigner la date de naissance !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }

    if (!item.professionSelected ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner la profession du client !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }

    if (!item.categorieClientSelected ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner la catégorie du client !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }


    if (!item.typeBeneficiaireSelected ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le type de béneficiaire !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }

    if (!item.contact ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez renseigner le contact !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }

    if (!item.typeBeneficiaireSelected ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le type de béneficiaire !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }

    if (!item.ethnieSelected ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner l'ethnie !",
        "bottom",
        "center");
        this.stepWizard = 1;
      return;
    }


    if (!item.identitePersonneAPrevenir ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez renseigner les informations de la personne à contacter !",
        "bottom",
        "center");
        this.stepWizard = 3;
      return;
    }

    if (!item.contactPersonneAPrevenir ) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le contact de la personne à contacter !",
        "bottom",
        "center");
        this.stepWizard = 3;
      return;
    }

    let statutBenef = _.find(this.listStatutBeneficiaires, (o)=> { return o.code == 'ACT'});
    if(!statutBenef || !statutBenef?.id){
      this.utilities.showNotification("snackbar-danger", "Aucun statut actif n'est parametré !",
      "bottom",
      "center");
    return;
    }

    if(item.tauxPc) {
      if(parseFloat(item.tauxPc) > 100) {
        this.utilities.showNotification("snackbar-danger", "Veuillez renseigner un taux valide !","bottom","center");
        return;
      }
    }

    // Dans le cas ou c'est un assuré, on verifie si ils ont renseigné une assurance par défaut
    // if(item.categorieClientSelected && (item.categorieClientSelected.code == 'ASSURE' && !item.assuranceSelected)) {
    //   this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner une assurance !",
    //   "bottom",
    //   "center");
    // return;
    // }else {
    //   if(item.categorieClientSelected.code != 'ASSURE') {
    //     if(item.assuranceSelected) {
    //       this.utilities.showNotification("snackbar-danger", "Le patient n'a pas la catégorie assuré, vous ne pouvez pas sélectionner une assurance !",
    //       "bottom",
    //       "center");
    //     }
    //   }
    // }

    objToSave.ethnieId = item.ethnieSelected?.id;
    objToSave.categorieClientId = item.categorieClientSelected?.id;
    objToSave.typeBeneficiaireId = item.typeBeneficiaireSelected?.id;
    objToSave.professionId = item.professionSelected?.id;
    objToSave.cdiId = item.centreDesImpotsSelected?.id;
    objToSave.assuranceId = item.assuranceSelected?.id;
    objToSave.groupeSanguinId = item.groupeSanguinelected?.id;
    objToSave.statutBeneficiaireId = statutBenef?.id;
    objToSave.tauxPc = item.tauxPc || null

    // Formatage des dates
    if(this.itemToSave.dateNais){
      objToSave.dateNais = moment(this.itemToSave.dateNais).format("DD/MM/YYYY")
    }

    if(this.itemToSave.dateExpirationPiece){
      objToSave.dateExpirationPiece = moment(this.itemToSave.dateExpirationPiece).format("DD/MM/YYYY") + ' 00:00:00';
    }

    if(this.itemToSave.dateEtablissementPiece){
      objToSave.dateEtablissementPiece = moment(this.itemToSave.dateEtablissementPiece).format("DD/MM/YYYY") + ' 00:00:00';
    } 

    // On vide les valeurs pour allourdir la requete
    objToSave.centreDesImpotsSelected = null;
    objToSave.categorieClientSelected = null;
    objToSave.typeBeneficiaireSelected = null;
    objToSave.assuranceSelected = null;
    objToSave.groupeSanguinelected = null;
    objToSave.professionSelected = null;
    objToSave.typeBeneficiaireSelected = null;
    objToSave.ethnieSelected = null;

    Swal.fire({
      title: "Client",
      text: objToSave?.id ? "Vous êtes sur le point de modifier un client. Voulez-vous poursuivre cette action ?" : "Vous êtes sur le point d'enregistrer un client. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        objToSave.nom = objToSave.nom.toUpperCase();
        objToSave.prenom = objToSave.prenom.toUpperCase();
        this.saveItem(objToSave); 
      }
    });
  }

  saveItem(item) {

    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer
      ]
    }

    this.busyGet = this.restClient.post((itemAEnregistrer.id ? 'beneficiaire/update' : 'beneficiaire/create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");

              this.patientSavedSucces.emit(res['items'][0]);
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes['itemPatient'] && changes['itemPatient'].currentValue)
    {
      this.itemToSave = {...changes['itemPatient'].currentValue};

      // Nous devrons formater les différentes dates
      if(this.itemToSave?.dateNais){
        let splitDateBySlash= this.itemToSave?.dateNais.split(" ")[0].split("/");
        this.itemToSave.dateNais = new Date(splitDateBySlash[2], splitDateBySlash[1] - 1, splitDateBySlash[0]);
      }

      if(this.itemToSave?.dateEtablissementPiece){
        let splitDateBySlash= this.itemToSave?.dateEtablissementPiece.split(" ")[0].split("/");
        this.itemToSave.dateEtablissementPiece = new Date(splitDateBySlash[2], splitDateBySlash[1] - 1, splitDateBySlash[0]);
      }

      if(this.itemToSave?.dateExpirationPiece){
        let splitDateBySlash= this.itemToSave?.dateExpirationPiece.split(" ")[0].split("/");
        this.itemToSave.dateExpirationPiece = new Date(splitDateBySlash[2], splitDateBySlash[1] - 1, splitDateBySlash[0]);
      }

    }
  }

}
