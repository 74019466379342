import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { frLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { Subscription } from 'rxjs'
import * as moment from 'moment';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import { enumStatut } from 'src/app/core/enumerator/enumerator';

@Component({
  selector: 'app-form-create-programme',
  templateUrl: './form-create-programme.component.html',
  styleUrls: ['./form-create-programme.component.scss']
})
export class FormCreateProgrammeComponent implements OnInit {

  @Input() planning;
  @Input() requete;

  itemMatin : any = {
    heureDebut : '06',
    minuteDebut : '00',
    heureFin: '12',
    minuteFin : '00'
  };


    busyGet: Subscription;
    busySave: Subscription;
    agendaDays: Object[] = [];
    medecins : Array<any> = [];
    itemToSave : any = {};
    agendaMonth: Object = "";
    agendaYear: number;
    nightHourFin : Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 6, 0, 0);;
    nightHourDebut :  Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 18, 0, 0);
    quota: number;
    morningHourDebut: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8, 0, 0);
    morningHourFin: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12, 0, 0);
    eveningHourDebut: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 14, 0, 0);
    eveningHourFin: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 17, 0, 0);
    years: number[] = [moment().year(), moment().add(1, 'y').year()]
    uniteFoncs: any[] = [];
    uniteFoncId: any; 

    listeHeuresMatin : any = [];
    listeMinutes : any = [];
    listeHeuresSoir : any = [];

    days: Object[] = [{
        index: 1,
        name: "Lundi"
    }, {
        index: 2,
        name: "Mardi"
    }, {
        index: 3,
        name: "Mercredi"
    }, {
        index: 4,
        name: "Jeudi"
    }, {
        index: 5,
        name: "Vendredi"
    }, {
        index: 6,
        name: "Samedi"
    }, {
        index: 0,
        name: "Dimanche"
    }]
    months: Object[] = [{
        index: 0,
        name: "Janvier"
    }, {
        index: 1,
        name: "Février"
    }, {
        index: 2,
        name: "Mars"
    }, {
        index: 3,
        name: "Avril"
    }, {
        index: 4,
        name: "Mai"
    }, {
        index: 5,
        name: "Juin"
    }, {
        index: 6,
        name: "Juillet"
    }, {
        index: 7,
        name: "Août"
    }, {
        index: 8,
        name: "Septembre"
    }, {
        index: 9,
        name: "Octobre"
    }, {
        index: 10,
        name: "Novembre"
    }, {
        index: 11,
        name: "Décembre"
    }];
    medecinId: any;
    motifId: any;
    listeMedecin: Object[] = [];
    listeMotif: Object[] = [];
    periodeDisponibilite: Date[] = [moment().toDate(), moment().add(5, "d").toDate()];
    ListeRequeteDialyse : any = [];
    beneficiaireId : any;

    dispoMatin = true;
    dispoSoir = true;
    dispoNuit = false;
    user: any = {};
    hasDejaRequete : boolean;
    itemSoir : any = {
      heureDebut : '14',
      minuteDebut : '00',
      heureFin : '18',
      minuteFin : '00'
    };

    constructor(private authService: AuthService, private _localeService: BsLocaleService, private restClientService: RestClientService, public modalRef: BsModalRef, private modalService: BsModalService, private utilities: UtilitiesService,
        public options: ModalOptions) {
        defineLocale(frLocale.abbr, frLocale);
        this._localeService.use('fr');
        this.user = this.authService.currentUserValue;

        this.getMinutes();
        this.getHeureSoir();
        this.getHeureMatin();
        
        if(this.options.initialState && this.options.initialState.requete) {
            this.hasDejaRequete = true;
            this.itemToSave.requestSelected = this.options.initialState.requete;
            this.requete = this.options.initialState.requete;
            this.beneficiaireId = this.options.initialState.benefeciaireDefautSelectedId;

        }else {
            this.hasDejaRequete = false;
        }
    }

    getMinutes() {
      for (let i = 0; i < 60; i++) {
       this.listeMinutes.push( (i < 10 ? '0'+i : i ) );
      }
    }

    getHeureMatin() {
      for (let i = 5; i < 13; i++) {
        this.listeHeuresMatin.push((i < 10 ? '0'+i : i ) )
      }
    }

    getHeureSoir() {
      for (let i = 14; i < 19; i++) {
        this.listeHeuresSoir.push((i < 10 ? '0'+i : i ) )
      }
    }


  getRequeteDialyse() {
    let request = {
      user: this.user.id,
      data: {
        statut : enumStatut.VALIDE
      },
    };

    this.busyGet = this.restClientService
      .post("dialyseRequete/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.ListeRequeteDialyse = res["items"];
          } else {
            this.ListeRequeteDialyse = [];
          }
        },
        (err) => {}
      );
  }
  
  
    ngOnInit() {
        this.dispoSoir = false;
        this.dispoMatin = false;

        this.getMedecin();

        if(!this.hasDejaRequete) {
            this.getRequeteDialyse();
        }
        
    }

    getMedecin() {

        // On vide la lsite des medecins selectionné
        this.medecins = [];

        let data = {
            "data": {
                // "uniteFoncId": this.uniteFoncId
            }
        }
        this.busyGet = this.restClientService.post('userUniteFonc/getByCriteria', data).subscribe({
            next: (res) => {
                if (res && res['items'] && res['items'].length) {
                    this.listeMedecin = res['items'];
                    this.medecinId = this.user.id;
                    
                    // if(this.planning?.id) {
                    //     console.log('nous sommes en modification');
                    //     let medecinSelected = _.find(this.listeMedecin, (o)=> { return o.userId == this.planning?.medecinId });
                    //     this.medecins = [medecinSelected?.userId];
                    // }
                }
                else this.listeMedecin = [];
            },
            error: (err) => {
                this.listeMedecin = [];
                this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
                    "bottom",
                    "center");
            },
            complete: () => { }
        })
    }

    // getMotif() {
    //     let data = {

    //         "data": {

    //         }
    //     }
    //     this.busyGet = this.restClientService.post('adminMotifDispo/getByCriteria', data).subscribe({
    //         next: (res) => {
    //             if (res && res['items'] && res['items'].length) {
    //                 this.listeMotif = res['items'];
    //             }
    //             else this.listeMotif = [];
    //         },
    //         error: (err) => {
    //             this.listeMotif = [];
    //             this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
    //                 "bottom",
    //                 "center");
    //         },
    //         complete: () => { }
    //     })
    // }
    

    confirmSaveItem() {

        if (!this.agendaDays) {
            this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner les jours de disponibilité !",
                "bottom",
                "center");
            return;
        }

        if (!this.periodeDisponibilite) {
            this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner la période de disponibilité !",
                "bottom",
                "center");
            return;
        }

        // if (!this.uniteFoncId) {
        //     this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner l'unité fonctionnelle!",
        //         "bottom",
        //         "center");
        //     return;
        // }

        if (!this.medecinId) {
            this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un médecin !",
                "bottom",
                "center");
            return;
        }

        if(!this.dispoMatin && !this.dispoSoir) {
            this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner une disponibilité !", "bottom","center");
            return;
        }

//         if (!this.itemMatin?.heureDebut) {
//             this.utilities.showNotification("snackbar-danger", "Veuillez préciser l'heure de début !",
//                 "bottom",
//                 "center");
//             return;
//         }

//         if (!this.itemSoir?.heureDebut) {
//           this.utilities.showNotification("snackbar-danger", "Veuillez préciser l'heure dU soir !",
//               "bottom",
//               "center");
//           return;
//       }

//       if (!this.itemMatin?.heureFin) {
//         this.utilities.showNotification("snackbar-danger", "Veuillez préciser l'heure defin du programme de la matinée !",
//             "bottom",
//             "center");
//         return;
//     }

//     if (!this.itemSoir?.heureFin) {
//       this.utilities.showNotification("snackbar-danger", "Veuillez préciser l'heure de fin du programme du soir !",
//           "bottom",
//           "center");
//       return;
//   }

  console.log(" this.itemToSave.requeteSelected ",this.itemToSave.requeteSelected);
  

      if(!this.itemToSave.requeteSelected && !this.requete) {
        this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner une requête de dialyse !",
        "bottom",
        "center");
        return;
      }

      // Verifier que l'heure de deb n'est pas superieur a l'heure de fin

      if(parseInt(this.itemMatin?.heureDebut) > parseInt(this.itemMatin?.heureFin)) {
        this.utilities.showNotification("snackbar-danger", "La période de la matinée n'est pas valide !",
        "bottom",
        "center");
    return;
      }

      if(parseInt(this.itemSoir?.heureDebut) > parseInt(this.itemSoir?.heureFin)) {
        this.utilities.showNotification("snackbar-danger", "La période de la soirée n'est pas valide !",
        "bottom",
        "center");
    return;
      }

      // Verifier si il a coché au moins matin ou soir
      if(!this.dispoMatin && !this.dispoSoir) {
        this.utilities.showNotification("snackbar-danger", "Veuillez préciser une disponibilité (Matin ou Soir) !","bottom","center");
        return;
      }


        // if (!this.morningHourDebut && !this.morningHourFin && !this.nightHourDebut ) {
        //     this.utilities.showNotification("snackbar-danger", "Veuillez préciser les horaires !",
        //         "bottom",
        //         "center");
        //     return;
        // }

        let objToSave = {
          medecinId: this.medecinId,
          dialyseRequeteId: this.itemToSave.requeteSelected ? this.itemToSave.requeteSelected.id : (this.requete ? this.requete?.id : null),
          heureDebutMatin: this.itemMatin.heureDebut+':'+this.itemMatin.minuteDebut,
          heureFinMatin: this.itemMatin.heureFin+':'+this.itemMatin.minuteFin,
          heureDebutSoir: this.itemSoir.heureDebut+':'+this.itemSoir.minuteDebut,
          heureFinSoir: this.itemSoir.heureFin+':'+this.itemSoir.minuteFin,
          datasDisponibilite : []
        }
       
        if(!this.dispoSoir) {
            objToSave.heureDebutSoir = null;
            objToSave.heureFinSoir = null;
        }

        if(!this.dispoMatin) {
            objToSave.heureDebutMatin = null;
            objToSave.heureFinMatin = null;
        }

        Swal.fire({
            title: "Programme dialyse",
            text: !this.planning?.id ? "Vous êtes sur le point d'enregistrer un programme de dialyse. Voulez-vous poursuivre cette action ?" : "Vous êtes sur le point de modifier un programme de dialyse. Voulez-vous poursuivre cette action ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0665aa",
            cancelButtonColor: "#aaa4a4",
            confirmButtonText: "Oui",
            cancelButtonText: 'Non',
        }).then((result) => {
            if (result.value) {
                this.createProgramme(objToSave);
            }
        });
    }


    createProgramme(item) {
        const datasDisponibilite = []
        if (!this.agendaDays.length || !this.periodeDisponibilite.length || !this.medecins) {
            this.utilities.showNotification("snackbar-danger",
                "Veuillez renseigner les champs obligatoires",
                "top",
                "right");
        }
        else {
            for (let index = /*moment().set({ 'year': this.agendaYear, 'month': this.agendaMonth['index'] }).startOf('month')*/moment(this.periodeDisponibilite[0]); index.isSameOrBefore(moment(this.periodeDisponibilite[1])/*moment().set({ 'year': this.agendaYear, 'month': this.agendaMonth['index'] }).endOf('month'), 'd'*/); index.add(1, 'd')) {
                const dayIndex = index.get('day');
                this.agendaDays.forEach(agendaDay => {
                    if (dayIndex === agendaDay['index']) datasDisponibilite.push({
                        'libelle': index.format("DD/MM/YYYY")
                    })
                });
            }
            
            if (!this.dispoMatin && !this.dispoSoir) {
                this.utilities.showNotification("snackbar-danger", "Veuillez cocher au moins une période de la journée pour indiquer vos heures de disponibilité",
                    "top",
                    "right");
                return;
            }
           

            // let listMedecins = [];
            // if(this.medecins?.length > 0) {
                
            //     this.medecins.forEach(medecin => {

            //         let data =  {
            //                 "id": this.planning ? this.planning.id : "",
            //                 "medecinId": medecin,
            //                 "adminUniteFonctionnelleId": this.uniteFoncId,
            //                 //"quota":this.quota,
            //                 //"date":"01/12/2020",
            //                 "heureDebutMatin": this.dispoMatin ? (this.morningHourDebut.getHours() < 10 ? ("0" + this.morningHourDebut.getHours()) : this.morningHourDebut.getHours()) + ":" + (this.morningHourDebut.getMinutes() < 10 ? ("0" + this.morningHourDebut.getMinutes()) : this.morningHourDebut.getMinutes()) : "",
            //                 "heureFinMatin": this.dispoMatin ? (this.morningHourFin.getHours() < 10 ? ("0" + this.morningHourFin.getHours()) : this.morningHourFin.getHours()) + ":" + (this.morningHourFin.getMinutes() < 10 ? ("0" + this.morningHourFin.getMinutes()) : this.morningHourFin.getMinutes()) : "",
            //                 "heureDebutSoir": this.dispoSoir ? (this.eveningHourDebut.getHours() < 10 ? ("0" + this.eveningHourDebut.getHours()) : this.eveningHourDebut.getHours()) + ":" + (this.eveningHourDebut.getMinutes() < 10 ? ("0" + this.eveningHourDebut.getMinutes()) : this.eveningHourDebut.getMinutes()) : "",
            //                 "heureFinSoir": this.dispoSoir ? (this.eveningHourFin.getHours() < 10 ? ("0" + this.eveningHourFin.getHours()) : this.eveningHourFin.getHours()) + ":" + (this.eveningHourFin.getMinutes() < 10 ? ("0" + this.eveningHourFin.getMinutes()) : this.eveningHourFin.getMinutes()) : "",
            //                 "heureDebutNuit": this.dispoNuit ? (this.nightHourDebut.getHours() < 10 ? ("0" + this.nightHourDebut.getHours()) : this.nightHourDebut.getHours()) + ":" + (this.nightHourDebut.getMinutes() < 10 ? ("0" + this.nightHourDebut.getMinutes()) : this.nightHourDebut.getMinutes()) : "",
            //                 "heureFinNuit": this.dispoNuit ? (this.nightHourFin.getHours() < 10 ? ("0" + this.nightHourFin.getHours()) : this.nightHourFin.getHours()) + ":" + (this.nightHourFin.getMinutes() < 10 ? ("0" + this.nightHourFin.getMinutes()) : this.nightHourFin.getMinutes()) : "",
            //                 "datasDisponibilite": datasDisponibilite,
            //                 "motifId": this.motifId
            //             }

            //         listMedecins.push(data);

            //     });
            // }

            item.datasDisponibilite = datasDisponibilite;

            let request = {
              user : this.user?.id,
              datas : [item]
            }

            this.busySave = this.restClientService.post('dialyseProgramme/create',request).subscribe({
                next: (res) => {
                    if (!res['hasError']) {
                        //this.notificationService.showMessageSucces("Création de l'agenda", 'Opération effectuée avec succès');
                        this.utilities.showNotification("snackbar-success",
                            this.utilities.formatMsgServeur(res['status']['message']),
                            "top",
                            "right");
                        this.modalService.setDismissReason('Yes');
                        this.modalRef.hide()
                    }
                    else this.utilities.showNotification("snackbar-danger",
                        this.utilities.formatMsgServeur(res['status']['message']),
                        "top",
                        "right");
                },
                error: (err) => this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
                    "bottom",
                    "center"),

                complete: () => { }
            })

        }

    }


}
