<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
        <label>N° dossier hospi :</label>
        <input
          style="font-size: 12px"
          class="form-control custom-input"
          placeholder="Numéro dossier"
          autocomplete="off"
          [(ngModel)]="itemToSave.hospiNumeroDossier"
          disabled
          name="hospiNumeroDossier"
          required
          type="text"
        />
      </div>
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
        <label>Date heure :</label>
        <input
          style="font-size: 12px"
          class="form-control custom-input"
          placeholder="Date heure"
          autocomplete="off"
          [(ngModel)]="itemToSave.dateVisite"
          disabled
          name="dateVisite"
          required
          type="text"
        />
      </div>
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
        <label>N° demande :</label>
        <input
          style="font-size: 12px"
          class="form-control custom-input"
          placeholder="Numéro demande"
          autocomplete="off"
          [(ngModel)]="itemToSave.noDemande"
          disabled
          name="noDemande"
          required
          type="text"
        />
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
        <label>Medecin traitant: </label>
        <input
          style="font-size: 12px"
          class="form-control custom-input"
          placeholder="Medecin"
          autocomplete="off"
          [(ngModel)]="itemToSave.medecinTraitant"
          disabled
          name="medecinTraitant"
          required
          type="text"
        />
      </div>
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
        <label>Unité fonctionnelle : </label>
        <input
          style="font-size: 12px"
          class="form-control custom-input"
          placeholder="Unité fonctionnelle"
          autocomplete="off"
          [(ngModel)]="itemToSave.uniteFoncLibelle"
          disabled
          name="uniteFoncLibelle"
          required
          type="text"
        />
      </div>
      <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
          <label>Cause hospitalisation : </label>
          <input
            style="font-size: 12px"
            class="form-control custom-input"
            placeholder="Cause hospitalisation"
            autocomplete="off"
            [(ngModel)]="itemToSave.causeHospi"
            disabled
            name="causeHospi"
            required
            type="text"
          />
        </div> -->
    </div>
  </div>

  <div class="col-md-12">
    <div class="row">
      <legend style="color: red; font-size: 11px">Patient</legend>
      <div class="col-md-11">
        <div class="row">
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
            <label>Matricule : </label>
            <input
              style="font-size: 12px"
              class="form-control custom-input"
              placeholder="Matricule"
              autocomplete="off"
              [(ngModel)]="itemToSave.matricule"
              disabled
              name="matricule"
              required
              type="text"
            />
          </div>

          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
            <label>Nom et prénoms : </label>
            <input
              class="form-control custom-input"
              placeholder="Nom & Prénoms"
              autocomplete="off"
              [(ngModel)]="itemToSave.patient"
              disabled
              name="patient"
              required
              type="text"
            />
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
            <label>Catégorie : </label>
            <input
              class="form-control custom-input"
              placeholder="Catégorie"
              autocomplete="off"
              [(ngModel)]="itemToSave.categorie"
              disabled
              name="categorie"
              required
              type="text"
            />
          </div>

          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
            <label>Genre : </label>
            <input
              style="font-size: 12px"
              class="form-control custom-input"
              placeholder="Sexe"
              autocomplete="off"
              [(ngModel)]="itemToSave.sexe"
              disabled
              name="sexe"
              required
              type="text"
            />
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
            <label>Age : </label>
            <input
              class="form-control custom-input"
              placeholder="Age"
              autocomplete="off"
              [(ngModel)]="itemToSave.age"
              disabled
              name="age"
              required
              type="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" *ngIf="oldAssur && oldAssur.length">
            <label
              >Assurances :
              <!-- <span class="required_champs">*</span> -->
            </label>
            <!-- <select
              (change)="onSelectAssaurance()"
              class="form-control custom_input"
              id="assuranceSelected"
              [(ngModel)]="currentAssurance"
              (change)="addAssurance()"
              name="assuranceSelected"
            >
              <option value="">-- Choisissez --</option>
              <option
                *ngFor="let item of itemsAssurances"
                [disabled]="item.disabled"
                [ngValue]="item"
              >
                {{ item.raisonSociale }}
              </option>
            </select> -->
            <table
              class="table table-bordered table-condensed table-striped table-reception"
            >
              <thead>
                <tr class="custom-table-head">
                  <th style="text-align: center">#</th>
                  <th>Assurance</th>
                  <th>Taux</th>
                  <th>N° Bon</th>
                  <th>N°Carte</th>
                  <!-- <th> Coût Assurance </th> -->
                  <!-- <th style="text-align: center; width: 20px">#</th> -->
                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="
                    let assurance of listAssurancesToSave;
                    let indice = index
                  "
                >
                  <td style="text-align: center">{{ indice + 1 }}</td>
                  <td>
                    {{
                      assurance?.raisonSociale ||
                        assurance?.libelle ||
                        assurance?.adminAssuranceRaisonSociale
                    }}
                  </td>
                  <td>
                    <input
                      [disabled]="assurance.isOld"
                      [ngModelOptions]="{ standalone: true }"
                      type="text"
                      class="form-control"
                      placeholder="Taux"
                      [(ngModel)]="assurance.taux"
                      mask="separator"
                      maxlength="3"
                    />
                  </td>
                  <td>
                    <input
                      [disabled]="assurance.isOld"
                      [ngModelOptions]="{ standalone: true }"
                      type="text"
                      class="form-control"
                      placeholder="N°Bon"
                      [(ngModel)]="assurance.numeroBon"
                    />
                  </td>
                  <td>
                    <input
                      [disabled]="assurance.isOld"
                      [ngModelOptions]="{ standalone: true }"
                      type="text"
                      class="form-control"
                      placeholder="N°Carte"
                      [(ngModel)]="assurance.numeroCarte"
                    />
                  </td>
                  <!-- <td> {{ assurance?.partAssurance | moneyFormat }}</td> -->
                  <!-- <td style="text-align: center">
                    <a
                      title="Supprimer assurance"
                      (click)="listAssurancesToSave.splice(indice, 1)"
                      style="color: red"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </td> -->
                </tr>

                <tr
                  *ngIf="
                    !listAssurancesToSave || listAssurancesToSave.length == 0
                  "
                >
                  <td colspan="6" style="text-align: center">
                    Aucune donnée disponible
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-5 mb-0">
            <fieldset class="custom-fieldset">
                <legend> Constantes </legend>

                <div class="row">

                    <div class="col-xl-12 col-lg-3 col-md-3 col-sm-12 mb-0 table-responsive">
                        <table class="table table-bordered table-striped table-hover table-details"
                            style="margin: 0 !important;">
                            <thead>
                                <tr class="custom-table-head">
                                    <th>#
                                      <a
                                          style="cursor: pointer"
                                          (click)="currentPage = 1; getPriseConstante()"
                                          title="Actualiser"
                                        >
                                          <i class="fa fa-redo-alt"></i></a>
                                    </th>
                                    <th>T(°C)</th>
                                    <th>TAG (mmHg)</th>
                                    <th>TAD (mmHg)</th>
                                    <th>Poids(Kg)</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let const of listConstantePatient; let indice = index"
                                   >
                                    <td>{{indice + 1}}</td>
                                    <td>{{const?.temperature}}</td>
                                    <td>{{const?.tag}}</td>
                                    <td>{{const?.tad}}</td>
                                    <td>{{const?.poids}}</td>
                                    <td>
                                        <a  (click)="openModal(const,templateDetailsPriseDeConstante,false,false,true)"> <i class="fa fa-list"></i></a> 

                                        &nbsp;&nbsp;&nbsp;&nbsp;<a (click)="openFormPriseConstante(templatePriseConstante,const)"> <i class="fa fa-edit"></i> </a>

                                    
                                    </td>
                                </tr>

                                <tr *ngIf="!listConstantePatient || listConstantePatient.length == 0">
                                    <td colspan="6" style="text-align: center;"> Aucune donnée disponible </td>
                                </tr>

                                <tr *ngIf="listConstantePatient && listConstantePatient.length > 0">
                                    <td colspan="6">
                                        <div class="row pagination-part">
                                            <div class="col-md-9 mb-0" style="text-align: center">
                                                <pagination [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                                                    [(ngModel)]="currentPage" [maxSize]="5" class="pagination-sm"
                                                    previousText="&lsaquo;" nextText="&rsaquo;"
                                                    (pageChanged)="pageChanged($event)"></pagination>
                                            </div>

                                            <!-- <div class="col-md-3">
                                                <span class="span_total_entree pull-right">Total : {{totalItems}}</span>
                                            </div> -->
                                        </div>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>

                    


                </div>
            </fieldset>
        </div>
        </div>
      </div>
      <div class="col-md-1">
        <!-- <img
          src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
          alt="Photo assuré"
          class="pull-right img-assure"
        /> -->
        <img
          [src]="imgUrl" (error)="handleError()"
          alt="Photo assuré"
          class="pull-right img-assure"
          style="width: 150px;height: 150px;"
        />
      </div>
    </div>
  </div>
</div>


<ng-template #templatePriseConstante>

  <ng-container>
      <div class="modal-header">
          <h4 class="modal-title pull-left">
             Prise de constante
          </h4>

          <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
              <mat-icon>close</mat-icon>
          </button>

      </div>
      <div class="modal-body">
          <app-form-prise-constant-vm
          (closeForm)="modalRef.hide()"
          [patientSelected]="dossierHospi"
          [listConstantePatient]="listConstantePatient"
        >
        </app-form-prise-constant-vm>
      </div>
  </ng-container>

</ng-template>


<ng-template #templateDetailsPriseDeConstante>
  <div class="modal-header">
      <h4 class="modal-title pull-left">
          Détails prise de constante
      </h4>

      <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
          <mat-icon>close</mat-icon>
      </button>

  </div>
  <div class="modal-body">
      <ng-container>

          <div class="row">
              <div class="col-12 mb-1">
                  <b> Date enregistrement : </b> {{ detailsPriseDeConstante?.createdAt }}
              </div>
          </div>
          <div class="row">

              <div class="col-6">
                  <table class="table table-bordered table-striped">
                      <tbody>
                          <tr>
                              <td class="label-details"> Temperature (°C) </td>
                              <td> {{ detailsPriseDeConstante?.temperature || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> TAG (mmHG) </td>
                              <td> {{ detailsPriseDeConstante?.tag || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> TAD (mmHG) </td>
                              <td> {{ detailsPriseDeConstante?.tad || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> Poids (Kg) </td>
                              <td> {{ detailsPriseDeConstante?.poids || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> Taille (Cm) </td>
                              <td> {{ detailsPriseDeConstante?.taille || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> IMC (Kg / m2) </td>
                              <td> {{ detailsPriseDeConstante?.imc || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> IMG (%) </td>
                              <td> {{ detailsPriseDeConstante?.img || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> Sucre </td>
                              <td> {{ detailsPriseDeConstante?.sucre || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> Albumine </td>
                              <td> {{ detailsPriseDeConstante?.albumine || "-" }} </td>
                          </tr>


                      </tbody>
                  </table>
              </div>

              <div class="col-6">

                  <table class="table table-bordered table-striped">
                      <tbody>

                          <tr>
                              <td class="label-details"> Pull (B/Min) </td>
                              <td> {{ detailsPriseDeConstante?.pulls || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> Fréq respiratoire (C / Min) </td>
                              <td> {{ detailsPriseDeConstante?.frequenceRespiratoire || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> Périmètre Cranien (cm) </td>
                              <td> {{ detailsPriseDeConstante?.pc || "-" }} </td>
                          </tr>

                          <tr>
                              <td class="label-details"> Périmètre Ombilical (cm) </td>
                              <td> {{ detailsPriseDeConstante?.po || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> Périmètre Brachial (cm) </td>
                              <td> {{ detailsPriseDeConstante?.pb || "-" }} </td>
                          </tr>
                          <tr>
                              <td class="label-details"> Zscore </td>
                              <td> {{ detailsPriseDeConstante?.zscore || "-" }} </td>
                          </tr>

                      </tbody>
                  </table>

              </div>

          </div>
      </ng-container>
  </div>

  <div class="modal-footer modal-footer-custom">

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <button type="button" class="btn btn-md btn-cancel pull-right" (click)="modalRef.hide()">Annuler</button>
      </div>
  </div>
</ng-template>
