<ng-container>
    <div class="row"  [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
        <div class="col-12 mb-3" *ngIf="itemToSave?.createdAt && itemToSave?.enregistrerPar">
            <b class="pull-right derniere-info"> Dernière modification le {{itemToSave?.createdAt}} par {{itemToSave?.enregistrerPar}} </b>
        </div>

        <div class="col-12">
                <div class="row">
                    <div class="col-2 mb-2">
                        <label>N° Demande:
                        </label>
                        <input class="form-control custom-input custom-input-details-cons" placeholder="N° Demande" autocomplete="off"
                            [(ngModel)]="itemToSave.numeroDemande" name="numeroDemande" [disabled]="true" required type="text">
                    </div>
    
                    <div class="col-2 mb-2">
                        <label>Date et Heure Cons: </label>
                        <input
                          class="form-control custom-input custom-input-details-cons"
                          placeholder="Date et Heure Cons"
                          autocomplete="off"
                          [(ngModel)]="consultation.createdAt"
                          name="createdAt"
                          [disabled]="true"
                          required
                          type="text"
                        />
                      </div>
        
                      <div class="col-2 mb-2">
                        <label>N° Consultation : </label>
                        <input
                          class="form-control custom-input custom-input-details-cons"
                          placeholder="N°Consultation"
                          autocomplete="off"
                          [(ngModel)]="consultation.numeroConsultation"
                          name="numeroConsultation"
                          [disabled]="true"
                          required
                          type="text"
                        />
                      </div>
        
                      <div class="col-3 mb-2">
                        <label>Medecin traitant : </label>
                        <input
                          class="form-control custom-input custom-input-details-cons"
                          placeholder="Medecin traitant"
                          autocomplete="off"
                          [(ngModel)]="currentPatient.medecin"
                          name="medecin"
                          [disabled]="true"
                          required
                          type="text"
                        />
                      </div>
        
                      <div class="col-3 mb-2">
                        <label>Unité fonctionnelle : </label>
                        <input
                          class="form-control custom-input custom-input-details-cons"
                          placeholder="Unité fonctionnelle"
                          autocomplete="off"
                          [(ngModel)]="currentPatient.adminUniteFonctionnelleLibelle"
                          name="adminUniteFonctionnelleLibelle"
                          [disabled]="true"
                          required
                          type="text"
                        />
                      </div>

                </div>
        </div>

        <fieldset class="custom-fieldset mb-2">
            <legend> Info patient </legend>

            <div class="col-12">
                <app-info-patient-consultation [currentPatient]="currentPatient" [withAssurances]="true"></app-info-patient-consultation>
            </div>
        </fieldset>

        

        <fieldset class="custom-fieldset ">
            <legend> Info demande d'hospitalisation</legend>
            <div class="row">
                <div class="col-4 mb-3">
                    <label>Unité fonctionnelle:<span class="required_champs">*</span>
                    </label>

                    <select class="form-control custom_input" id="uniteFocntionnelleSelected"
                    [(ngModel)]="itemToSave.uniteFocntionnelleSelected"  [disabled]="consultation?.isClotured" name="uniteFocntionnelleSelected">
                    <option value="">-- Choisissez --</option>
                    <option *ngFor="let item of listUniteFonctionnelles" [ngValue]="item">
                        {{ item?.libelle }}
                    </option>
                </select>

                    <!-- <input class="form-control custom-input" placeholder="Unité fonctionnelle" autocomplete="off"
                        [(ngModel)]="itemToSave.telephone1" name="telephone1" mask="separator" required type="text"> -->
                </div>

                <div class="col-4 mb-3">
                    <label>Type hospitalisation :<span class="required_champs">*</span>
                    </label>

                    <select class="form-control custom_input" id="typeHospitalisationSelected"
                    [(ngModel)]="itemToSave.typeHospitalisationSelected"  [disabled]="consultation?.isClotured" name="typeHospitalisationSelected">
                    <option value="">-- Choisissez --</option>
                    <option *ngFor="let item of listTypeHospitalisations" [ngValue]="item">
                        {{ item?.libelle }}
                    </option>
                </select>

                </div>

                <div class="col-4 mb-3">
                    <label>Date hospi :<span class="required_champs">*</span>
                    </label>
                   
                    <input
                    type="text"
                    placeholder="Date d'hospitalisation"
                    class="form-control"
                    name="dateHospi"
                    [(ngModel)]="itemToSave.dateHospi"
                    bsDatepicker
                    [maxDate]="dateActuelle"
                    autocomplete="off"
                    [disabled]="consultation?.isClotured" 
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-dark-blue'
                    }"
                  />

                </div>
            </div>


            <div class="row">
                <div class="col-4 mb-3">
                    <label>Mode admission :<span class="required_champs">*</span>
                    </label>

                    <select class="form-control custom_input" id="modeAdminssionSelected"  [disabled]="consultation?.isClotured" 
                    [(ngModel)]="itemToSave.modeAdminssionSelected"  [disabled]="consultation?.isClotured" name="modeAdminssionSelected">
                    <option value="">-- Choisissez --</option>
                    <option *ngFor="let item of listeModeAdmissions" [ngValue]="item">
                        {{ item?.libelle }}
                    </option>
                </select>
                </div>

                <div class="col-4 mb-3">
                    <label>Type admission :<span class="required_champs">*</span>
                    </label>

                    <select class="form-control custom_input" id="typeAdminissionSelected"  [disabled]="consultation?.isClotured" 
                    [(ngModel)]="itemToSave.typeAdminissionSelected"  [disabled]="consultation?.isClotured" name="typeAdminissionSelected">
                    <option value="">-- Choisissez --</option>
                    <option *ngFor="let item of listeTypesAdminissions" [ngValue]="item">
                        {{ item?.libelle }}
                    </option>
                </select>
                </div>

                <div class="col-4 mb-3" *ngIf="itemToSave.typeHospitalisationSelected && itemToSave.typeHospitalisationSelected?.code == 'HP'">
                    <label>Nbre de jr hospi:<span class="required_champs" >*</span>
                    </label>
                    <input class="form-control custom-input" placeholder="Nbre de jr hospi" autocomplete="off"
                        [(ngModel)]="itemToSave.nbreJourHospi" name="nbreJour" mask="separator"  [disabled]="consultation?.isClotured"  type="text" maxlength="4">
                </div>
            </div>


            <div class="row">
                <div class="col-4 mb-3">
                    <label>Cause hospitalisation:<span class="required_champs">*</span>
                    </label>
                    <textarea rows="2" class="form-control custom-input" name="observation"  [disabled]="consultation?.isClotured" 
                        placeholder="Cause hospitalisation" [(ngModel)]="itemToSave.causeHospi">
                </textarea>
                </div>

                <div class="col-6 mb-3" *ngIf="itemToSave.typeHospitalisationSelected && (itemToSave.typeHospitalisationSelected?.code == 'MEO' || itemToSave.typeHospitalisationSelected?.code == 'MO')">
                    <div class="row">
                        <fieldset class="custom-fieldset">
                            <legend> Info mise en observation</legend>

                            <div class="row">
                                <div class="col-4">
                                    <label> Durée (Heure) </label>
                                    <input class="form-control custom-input" disabled placeholder="Durée (Heure)" autocomplete="off"
                                        [(ngModel)]="itemToSave.dureeMo"  name="duree" maxlength="10"
                                        type="text">
                                </div>

                                <div class="col-4">
                                    <label> Heure début (Hh:mn) </label>
                                    <input [dropSpecialCharacters]="false" class="form-control custom-input" placeholder="Heure début (Hh:mn)"
                                        autocomplete="off" [(ngModel)]="itemToSave.heureDebut" name="heureDebut"  (change)="getDureeMiseEnObservation()"  (keypress)="getDureeMiseEnObservation()" (keyup)="getDureeMiseEnObservation()"
                                       type="text" mask="Hh:m0"  [disabled]="consultation?.isClotured" >
                                </div>

                                <div class="col-4">
                                    <label> Heure fin (Hh:mn) </label>
                                    <input [dropSpecialCharacters]="false" class="form-control custom-input" placeholder="Heure fin (Hh:mn)" autocomplete="off" (change)="getDureeMiseEnObservation()"  (keypress)="getDureeMiseEnObservation()" (keyup)="getDureeMiseEnObservation()"
                                        [(ngModel)]="itemToSave.heureFin" mask="Hh:m0"  [disabled]="consultation?.isClotured" name="heureFin" 
                                        type="text">
                                </div>
                            </div>

                        </fieldset>
                    </div>
                </div>
            </div>

        </fieldset>


        <div class="row"  *ngIf="!isHistoConsultation">
            <ng-container>
                <div class="btn-manger-part">

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="padding: 0;">

                        <button type="button" class="btn btn-md custom_btn_primary pull-right"
                            (click)="confirmSaveItem(itemToSave)" [class.auth-spinner]="loading" [disabled]="loading || consultation?.isClotured">
                            {{itemToSave?.id ? 'Modifier' : 'Enregistrer'}} </button>

                        <button type="button" class="btn btn-md btn-cancel pull-right"
                            (click)="closeFormulaire()">Fermer</button>
                    </div>

                </div>
            </ng-container>
        </div>
    </div>

</ng-container>