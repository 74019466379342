
<form class="m-1">
  <div class="row" [ngBusy]="{
    busy: busyGet,
    message: 'Veuillez patienter ...',
    backdrop: false
  }">
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <ng-container>
        <div class="details-info-part">
          <div class="row">

            <div class="col-md-4 col-sm-12">

              <div class="row">
                <div class="col-md-6 col-sm-12 mb-2">
                  <label>N°Demande :</label>
                  <input class="form-control custom-input" placeholder="Numero demande" autocomplete="off"
                    [(ngModel)]="itemReglement.code" [disabled]="true" name="code" required type="text" />
                </div>
  
                <div class="col-md-6 col-sm-12 mb-2">
                  <label>Date heure enreg. :</label>
                  <input class="form-control custom-input" placeholder="Date et heure" autocomplete="off"
                    [(ngModel)]="itemReglement.createdAt" [disabled]="true" (keyup.enter)="SaveItem(itemReglement)"
                    name="numRecuTic" required type="text" />
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-2">
                  <label>Unité fonctionnelle :</label>
                  <!-- <select class="form-select custom_input" disabled id="magasinOrigin" [(ngModel)]="
                      itemReglement.uniteFoncId ?? itemToSave.uniteFoncId
                    " name="magasinOrigin">
                    <option value="">-- Choisissez --</option>
                    <option *ngFor="let item of listeUnit" [ngValue]="item.id">
                      {{ item.libelle }}
                    </option>
                  </select> -->
                  <input [(ngModel)]="currentUniteFonct.libelle"
                                      class="form-control custom-input pull-left" name="uniteFc" disabled />
                </div>


                <div class="col-12 mb-3">
                  <label>Observation</label>
                  <textarea class="form-control custom-input" placeholder="Observation" autocomplete="off"
                    [(ngModel)]="itemToSave.observation" (keyup.enter)="SaveItem(itemToSave)" name="observation" required
                    type="text"></textarea>
                </div>

              </div>
            </div>


            <div class="col-md-8">
              <fieldset class="custom-fieldset">
                <legend> Détails produits </legend>
  
                <div class="row">
  
                  <div class="col-md-6 col-sm-12 mb-3">
                    <label>Medicament :</label>
                    <!-- <select class="form-control custom_input" id="medicament" required
                      [(ngModel)]="itemToSaveChamps.medicamentId" name="medicament">
                      <option value="">-- Choisissez --</option>
                      <option *ngFor="let item of medicaments" [ngValue]="item.id">
                        {{ item.libelle }}
                      </option>
                    </select> -->

                    <!-- <app-search-medicament
                    (itemMedicamentSelected)="medicamentSelected($event)"
                    [cancelItemSelected]="refreshValue" [hasSearchIdMedcoc]="false">
                </app-search-medicament> -->

                <app-search-medoc-by-unite-fonctionnelle  (itemMedicamentSelected)="medicamentSelected($event)"
                [cancelItemSelected]="refreshValue" [hasSearchIdMedcoc]="false"></app-search-medoc-by-unite-fonctionnelle>


                  </div>
  
                  <div class="col-md-3 col-sm-12 mb-3">
                    <label>Quantité :</label>
                    <input class="form-control custom-input" placeholder="Quantié" autocomplete="off"
                      [(ngModel)]="itemToSaveChamps.quantiteDemande" name="quantiteTransferee" type="text" mask="separator" />
                  </div>
  
                  <div class="col-md-2 col-sm-12 mb-3">
                    <button  type="button" class="btn btn-md btn-add pull-right" (click)="
                        addMedoc(itemToSave, itemToSaveChamps, itemReglement)">
                      Ajouter
                    </button>
                  </div>
  
                </div>
  
                <div class="row">
                  <div class="col-12">
                    <table class="table table-condensed table-striped table-hover">
                      <thead>
                        <tr class="custom-table-head">
                          <th>Médicaments</th>
                          <th>Quantité demandé</th>
                          <th class="txt-align-center">Actions</th>
                        </tr>
                      </thead>
        
                      <tbody>
                        <tr *ngFor="let item of itemsMedicament; let indice = index">
                          <td>{{ item?.nomCommercialMedicament }}</td>
                          <td>
                            <input type="text" mask="separator" class="form-control" name="quantiteDemande"
                            autocomplete="off"  [ngModelOptions]="{standalone: true}" [disabled]="!item.isUpdated"
                            [(ngModel)]="item.quantiteDemande" id="quantiteDemande"
                            style="width:140px">
                            </td>

                          <td>
                            <div class="custom-td-action">
                              <a title="Modifier" (click)="item.isUpdated = !item.isUpdated" class="custom-btn-update">
                                <i class="fa fa-edit" *ngIf="!item?.isUpdated"></i>
                                <i class="fa fa-check" *ngIf="item?.isUpdated"></i>
                              </a>
                              <a title="Supprimer" (click)="deleteMedicament(indice)" class="custom-btn-delete">
                                <i class="fa fa-trash"></i></a>
                            </div>
                          </td>
                        </tr>

                        <tr *ngIf="!itemsMedicament || itemsMedicament.length == 0">
                          <td colspan="3" class="txt-align-center"> Aucune donnée disponible</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
  
              </fieldset>
            </div>
            
          </div>
         
        
          <!-- <div class="row">
            
          </div> -->
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row btn-manger-part">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

      <button type="button" class="btn btn-md custom_btn_primary pull-right" (click)="confirmSaveItem(itemToSave)">
        {{itemReglement.id ? 'Modifier' : 'Enregistrer'}}
      </button>

      <button type="button" class="btn btn-md btn-cancel pull-right" (click)="handleClose()">
        Fermer
      </button>

    </div>
  </div>
</form>