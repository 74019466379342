import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/service/auth.service";
import { RestClientService } from "src/app/core/service/rest-client.service";
import { UtilitiesService } from "src/app/core/service/utilities.service";
import Swal from "sweetalert2";
import * as _ from "lodash";

@Component({
  selector: "app-form-produits-unit-fonct",
  templateUrl: "./form-produits-unit-fonct.component.html",
  styleUrls: ["./form-produits-unit-fonct.component.scss"],
})
export class FormProduitsUnitFonctComponent implements OnInit {
  itemToSave: any = {};
  user: any = {};
  busyGet: Subscription;
  listeUnit: Array<any> = [];
  loading: boolean = false;
  listCaisier: Array<any> = [];
  medicaments: Array<any> = [];
  objToSave: {};
  active: Boolean = true;
  itemsMedicament: Array<any> = [];
  isUpdate : boolean = false;
  refreshValue : any;
  currentMedicament : any = {};
  objToSaveChamp: any = {
    // libelle: "",
    userId: "",
    uniteFoncId: "",
    observation: "",
    datasMedicaments: [],
  };

  itemToSaveChamps : any = {};

  @Input() itemReglement: any = {};
  @Output() close: EventEmitter<number> = new EventEmitter();
  currentUniteFonct: any={};

  constructor(
    private authService: AuthService,
    private restClient: RestClientService,
    private utilities: UtilitiesService
  ) {
    this.user = this.authService.currentUserValue;
    this.currentUniteFonct = this.authService.getActiveUniteFonctionnelle()
    this.itemToSave.uniteFoncId = this.currentUniteFonct.id
    this.itemReglement.uniteFoncId = this.currentUniteFonct.id

  }
  updateMedicament(value, index) {
     let libC = this.medicaments.find((e) => e.id == value.medicamentId);
     this.itemToSaveChamps = {
       medicamentId: libC.id,
       quantiteDemande: value.quantiteDemande,
     };
    this.itemsMedicament.splice(index, 1);
    this.objToSaveChamp.datasMedicaments.splice(index, 1);
  }

  medicamentSelected($event) {
   
    if($event) {

      console.log(" $event ",$event);
      
      this.currentMedicament = { ...$event };
      this.itemToSaveChamps.medicamentId = this.currentMedicament.medicamentId;
    }
  }

  SaveItem(item?) {

    this.loading = true;
    var request = {
      user: this.user.id,
      datas: [item],
    };

    this.busyGet = this.restClient.post('gestMatDemandeService/'+(this.isUpdate ? 'update':'create'), request).subscribe(
      (res) => {
        this.loading = false;
        if (!res["hasError"]) {
          if (res["items"] && res["items"].length > 0) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
           
            this.close.emit();
          }
        } else {
          if (res["status"] && res["status"]["message"]) {
            this.utilities.showNotification(
              "snackbar-danger",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );
          }
        }
      },
      (err) => {
        this.utilities.showNotification(
          "snackbar-danger",
          this.utilities.getMessageEndPointNotAvailble(),
          "bottom",
          "center"
        );
        this.loading = false;
      }
    );
  }
  
  confirmSaveItem(item) {

    // Verifier si l'observation est renseigné
    // if(!this.itemToSave.observation) {
    //   this.utilities.showNotification(
    //     "snackbar-danger",
    //     "Veuillez renseigner l'observation !",
    //     "bottom",
    //     "center"
    //   );
    //   return;
    // }

    if (!this.itemToSave.uniteFoncId) {
         this.utilities.showNotification(
           "snackbar-danger",
           "Veuillez renseigner l'unité fonctionnelle !",
           "bottom",
           "center"
         );
         return;
    }

    let objToSave = {
      id : this.itemReglement.id || null,
      userId : this.user?.id,
      uniteFoncId : this.itemToSave.uniteFoncId,
      observation : this.itemToSave.observation,
      datasMedicaments : this.itemsMedicament
    }

    Swal.fire({
      title: "Demande produit",
      text: item?.id
        ? "Voulez-vous poursuivre la modification ?"
        : "Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.SaveItem(objToSave);
      }
    });
  }

  handleClose() {
    this.objToSaveChamp = {
      // libelle: "",
      userId: "",
      uniteFoncId: "",
      observation: "",
      datasMedicaments: [],
    };
    this.itemsMedicament = [];
    this.close.emit();
  }

  addMedoc(item?, dataChamp?, regle?) {
    // if (!item || !item.libelle) {
    //   this.utilities.showNotification(
    //     "snackbar-danger",
    //     "Veuillez renseigner le libelle svp !",
    //     "bottom",
    //     "center"
    //   );
    //   return;
    // }
    //  if (!item || !item.uniteFoncId) {
    //    this.utilities.showNotification(
    //      "snackbar-danger",
    //      "Veuillez renseigner l'unité fonctionnelle !",
    //      "bottom",
    //      "center"
    //    );
    //    return;
    //  }

    // if (!item || !item.observation) {
    //   this.utilities.showNotification(
    //     "snackbar-danger",
    //     "Veuillez renseigner l'observation !",
    //     "bottom",
    //     "center"
    //   );
    //   return;
    // }
    if (!dataChamp.medicamentId) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner le medicament !",
        "bottom",
        "center"
      );
      return;
    }

    if (!dataChamp.quantiteDemande) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner la quantité demandé !",
        "bottom",
        "center"
      );
      return;
    }

    let itemChamp = {...this.itemToSaveChamps};
    let medoc = {...this.currentMedicament};

    let isExist = !!this.itemsMedicament.filter((im:any)=>im.medicamentId == dataChamp.medicamentId).length
    if (isExist) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Ce medicament est dejà dans la liste !",
        "bottom",
        "center"
      );
      return;
    }
    this.itemsMedicament.push({
      quantiteDemande : itemChamp.quantiteDemande,
      medicamentId : itemChamp.medicamentId,
      nomCommercialMedicament : medoc?.pharmacieMedicamentLibelle,
    });

    // On actualise les champs
    this.currentMedicament = {};
    this.refreshValue = new Date().getTime();
    this.itemToSaveChamps = {};

    // Swal.fire({
    //   title: "Demande produit",
    //   text:
    //     this.objToSaveChamp?.uniteFoncId &&
    //     "Vous êtes sur le point d'Ajouter une demande. Voulez-vous poursuivre cette action ?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#0665aa",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Oui",
    //   cancelButtonText: "Non",
    // }).then((result) => {
    //   if (result.value) {
    //     let libC = this.medicaments.find((e) => e.id == dataChamp.medicamentId);
    //     this.itemsMedicament.push({
    //       ...dataChamp,
    //       nomCommercialMedicament: libC.libelle,
    //     });
    //     console.log("this.itemsMedicament", this.itemsMedicament);

    //     if (regle?.id) {
    //       this.objToSaveChamp.datasMedicaments = this.itemsMedicament;
    //       console.log("modifie", this.objToSaveChamp);
    //     } else {
    //       this.objToSaveChamp.datasMedicaments.push(dataChamp);
    //       console.log("ajou", this.objToSaveChamp);
    //     }
    //     console.log("this.objToSaveChamp", this.objToSaveChamp);
    //     this.itemToSaveChamps = {
    //       medicamentId: "",
    //       quantiteDemande: "",
    //     };
    //     objToSave.libelle = objToSave.libelle.toUpperCase();
    //   }
    // });
  }

  deleteMedicament(index) {
    this.itemsMedicament.splice(index, 1);
    this.objToSaveChamp.datasMedicaments.splice(index, 1);
  }

  // getUnit() {
  //   let request = {
  //     user: this.user.id,
  //     data: {},
  //   };
  //   this.busyGet = this.restClient
  //     .post("adminUniteFonctionnelle/getByCriteria", request)
  //     .subscribe(
  //       (res) => {
  //         if (res && res["items"]) {
  //           this.listeUnit = res["items"];
  //         } else {
  //           this.listeUnit = [];
  //         }
  //       },
  //       (err) => {}
  //     );
  // }

  // getMedicament() {
  //   let request = {
  //     user: this.user.id,
  //     data: {},
  //   };
  //   this.busyGet = this.restClient
  //     .post("pharmacieMedicament/getByCriteria", request)
  //     .subscribe(
  //       (res) => {
  //         if (res && res["items"]) {
  //           this.medicaments = res["items"];
  //         } else {
  //           this.medicaments = [];
  //         }
  //       },
  //       (err) => {}
  //     );
  // }

  ngOnInit(): void {
    this.itemToSave = {...this.itemReglement || {}}

    if(this.itemToSave?.id) {
      this.isUpdate = true;
    }else {
      this.isUpdate = false;
    }
    // this.getMedicament();
    // this.getUnit();
    this.itemsMedicament = this.itemReglement.medicaments
      ? this.itemReglement.medicaments
      : [];
  }
}