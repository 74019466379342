import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import { enumStatut } from 'src/app/core/enumerator/enumerator';

@Component({
  selector: 'app-form-prise-constant-vm',
  templateUrl: './form-prise-constant-vm.component.html',
  styleUrls: ['./form-prise-constant-vm.component.scss']
})
export class FormPriseConstantVmComponent implements OnInit {

  itemToSave : any = {};
  listMedecins : Array<any> = [];
  busySave: Subscription;
  loading : boolean;
  @Input() patientSelected : any = {};
  @Input() listConstantePatient:any = {}
  @Input()  priseConstanteToUpdate : any = {};
  @Output() closeForm = new EventEmitter<any>();
  user: any = {};
  imgUrl: any;
  patientAge = ''
 
  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
  }

  fermertureFormulaire(){
    this.closeForm.emit(true);
  }

  getImcAndImg() {
     try {
      let it={...this.itemToSave}
      let sexe = this.itemToSave.beneficiaireSexe ='M' ? 1 : 0;
      let age = this.itemToSave.beneficiaireAge ? this.itemToSave.beneficiaireAge : null;
      if(it.taille && age){
        age = age.substring(0, age.indexOf('an'))
        this.itemToSave.imc= (it.poids/(it.taille*0.01)**2)?.toFixed(2)
        this.itemToSave.img = (1.2 *this.itemToSave.imc  + 0.23 * age - 10.8 * sexe - 5.4)?.toFixed(2)
      }
     } catch (error) {
        console.log(" error ",error);
        
     }  
     
  }

  getMedecin() {
    let request = {
      user: this.user.id,
      data: {
        isMedecin: true
      }
    }

   this.restClient.post('user/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {

            console.log(" utilisateur ",res);
            
            this.listMedecins = _.orderBy(res['items'], ['nom'], ['asc']); 

            // Preselectionner le medecin
            console.log("itemToSave: ",this.itemToSave)
            console.log("this.itemToSave?.praticienId: ",this.itemToSave?.praticienId)
            this.itemToSave.medecinSelected = _.find(this.listMedecins, (o) =>  { return o.id == this.itemToSave?.praticienId});
          }
          else {
            this.listMedecins = [];
          }
        },
        err => {
        }
      );
  }

  confirmSaveItem(item) {

    let objToSave = Object.assign({}, item);

    if (!item.temperature) {
      this.utilities.showNotification("snackbar-danger", "Veuillez préciser la température !",
        "bottom",
        "center");
      return;
    }

    if (!item.tag) {
      this.utilities.showNotification("snackbar-danger", "Veuillez préciser le TAG !",
        "bottom",
        "center");
      return;
    }

    if (!item.poids) {
      this.utilities.showNotification("snackbar-danger", "Veuillez renseigner le poids !",
        "bottom",
        "center");
      return;
    }

    if (!this.itemToSave?.id) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un patient !",
        "bottom",
        "center");
      return;
    }

    // if (!item.medecinSelected) {
    //   this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un médecin !",
    //     "bottom",
    //     "center");
    //   return;
    // }

    objToSave.medecinId = this.itemToSave?.medecinSelected?.id;
    objToSave.accueilId = this.itemToSave?.id;
    objToSave.medecinSelected = null;
    // objToSave.id = this.priseConstanteToUpdate?.id || null

    Swal.fire({
      title: "Prise de constante",
      text: objToSave?.id ? "Vous êtes sur le point de modifier une prise de constante. Voulez-vous poursuivre cette action ?" : "Vous êtes sur le point d'enregistrer une prise de constante. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.saveItem(objToSave);
      }
    });
  }

  saveItem(item) {

    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);
    itemAEnregistrer.type = enumStatut.PRISE_DE_CONSTANTE_GENERALISTE
    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer
      ]
    }

    this.busySave = this.restClient.post('hospiPriseConstante/'+(item?.id ? 'update':'update'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
                // this.cancelItem(true);
                this.itemToSave = {};
                this.fermertureFormulaire();
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    // debugger
    if(changes['patientSelected'] && changes['patientSelected'].currentValue)
    {
      this.patientAge = {...changes['patientSelected'].currentValue}.age;
      this.imgUrl = {...changes['patientSelected'].currentValue}.imgUrl
  
    }
    // debugger
    if(changes['listConstantePatient'] && changes['listConstantePatient'].currentValue)
    {
      this.itemToSave = {...changes['listConstantePatient'].currentValue}[0];
      console.log('Item to save : ',this.itemToSave);
      this.itemToSave.identiteMedecin = this.itemToSave.userNom + ' '+ this.itemToSave.userPrenom;
      this.itemToSave.patient = this.itemToSave.nomPatient + ' '+ this.itemToSave.prenomPatient;
      // this.itemToSave.temperature = 
      // this.itemToSave = {this.itemToSave,...changes['listConstantePatient'].currentValue};
      // this.itemToSave.identiteBeneficiaire = this.itemToSave.beneficiaireNom + ' '+ this.itemToSave.beneficiairePrenom;
      // this.getMedecin();
    }


    

    // this.imgUrl = this.itemToSave.imgUrl
      if(!this.imgUrl && this.itemToSave.beneficiaireMatricule){
        this.getInfoBenef(this.itemToSave.beneficiaireMatricule)
      }

  }


  getInfoBenef(matricule:any){
    let request = {
      user: this.user.id,
      data: {
        matriculeBeneficiaire: matricule
    }

    }
    console.log('data sent to server: ',request);
    this.restClient.post('beneficiaire/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.imgUrl = res['items'][0].imgUrl;
          }
          else {
            this.imgUrl=null
          }
        },
        err => {
        }
      );
  }

  handleError(){
    this.imgUrl="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
  }

}
