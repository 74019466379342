
    <div class="" [ngBusy]="{busy: busyGet,message: 'Veuillez patienter ...', backdrop: false}">
        <table class="table table-condensed">
            <thead>
              <tr>
                <th colspan="11">
                  <div class="row mt-4">
                    <!-- <div class="col-md-3">
                      <input [(ngModel)]="itemToSearch.libelle"
                        class="form-control custom-input pull-left" placeholder="Rechercher ..."
                        (keyup)="currentPage = 1; getItems()" />
                    </div> -->

                    <div class="col-md-4 col-sm-12">
                        <ng-select [(ngModel)]="itemToSearch.uniteFoncId" [disabled]="!isLivraisonAuService" placeholder="Choisissez une unité fonctionnelle"
                            name="uniteFc" id="uniteFc" [multiple]="false"
                            (change)="currentPage = 1; getItems(itemToSearch.uniteFoncId)" [closeOnSelect]="true">
                            <ng-option *ngFor="let item of itemsUniteFonctionnelle" [value]="item.id">
                                {{item?.libelle}}
                            </ng-option> 
                        </ng-select>
                    </div>
                    <!-- <div class="col-md-8" *ngIf="!isLivraisonAuService">
                      <button type="button" class="btn btn-md custom_btn_primary pull-right"
                        (click)="openModal(null, templateCrud)">
                        <i class="fa fa-plus"></i>&nbsp; Nouvelle demande
                      </button>
                    </div> -->

                  
                  </div>
                </th>
              </tr>
              <tr class="custom-table-head">
                <th  class="txt-align-center-width-40"> #</th>
                <th  class="txt-align-center-width-40">
                  <a style="cursor: pointer" (click)="currentPage = 1; getItems()" title="Actualiser">
                    <i class="fa fa-redo-alt"></i></a> 
                </th>
                <th>N° Livraison</th>
                <th>N° Demande</th>
             
                <!-- <th>Unité fonctionnelle</th> -->
                <th>Observ. demande</th>
                <th>Observ. livraison</th>
                <th class="txt-align-center">Statut livraison</th>
                <th>Enregistré le</th>
                <th>Enregistré par</th>
                <th class="txt-align-center" style="width:170px">
                  Actions
                </th>
              </tr>
            </thead>
        
            <tbody *ngFor="let item of items; let indice = index" [class.row-paire]="indice % 2 == 0">
              <tr>
                <td class="txt-align-center">{{ indice + 1 }}</td>
                <td>
                  <img src="assets/images/images-app/details_open.png" class="img-details"
                    *ngIf="!item?.isDetailsOpen" (click)="onToggle(item)" />
                  <img src="assets/images/images-app/details_close.png" class="img-details"
                    *ngIf="item?.isDetailsOpen" (click)="onToggle(item)" />
                </td>
                <td>{{ item?.code }}</td>
                <td>{{ item?.gestMatDemandeServiceCode }}</td>
                <!-- <td>{{item?.dataDemandeService?.adminUniteFonctionnelleLibelle}}</td> -->
                <td>{{ item?.dataDemandeService?.observation }}</td>
                <td>{{ item?.observation }}</td>
                <td class="txt-align-center"> 
                  <span class="badge" [class.badge-success]="item?.statut == 'TERMINE'"  [class.badge-danger]="item?.statut == 'ANNULE'">{{item?.statut}}</span>
             
                  <!-- <span *ngIf="item?.isLivre "
                  class="badge badge-success span-true-css">Effectuée</span>
              <span  *ngIf="!item?.isLivre || item?.isLivre == 'false'" 
                  class="badge badge-danger span-false-css">Pas encore effectué </span> -->
              </td>
        
              <td>{{ item?.createdAt }}</td>
                <td>{{ (item?.enregistrePar || item?.enregistrePar) | uppercase }}</td>

                <td *ngIf="isLivraisonAuService && !confirmReception">
                    <!-- <div class="custom-td-action">
                        <button title="Enregistrer une livraison" (click)="openModal(item,templateEnregistrerLivraison)"
                            class="btn custom_btn_primary custom-btn-update" >
                            Enregister une livraison </button> 
                    </div> -->
                </td>

                <td *ngIf="!isLivraisonAuService && confirmReception">
          
                    <div class="custom-td-action" *ngIf="item?.statut?.toLowerCase() == statutEnum.EN_ATTENTE_DE_RECEPTION?.toLowerCase() || item?.statut?.toLowerCase() == statutEnum.ATTENTE_RECEPTION?.toLowerCase()">
                        <button title="Confirmer reception" (click)="confirmReceptionArticle(item)"
                            class="btn custom_btn_primary custom-btn-update"
                           >
                            Confirmer reception </button>
                    </div>
                </td>

                <td class="txt-align-center" *ngIf="!isLivraisonAuService && !confirmReception">
                  <!-- <div class="custom-td-action">
                    <a title="Modifier" (click)="openModal(item, templateCrud)" class="custom-btn-update">
                      <i class="fa fa-edit"></i></a>
        
                    <a title="Supprimer" (click)="confirmDelete(item.id)" class="custom-btn-delete">
                      <i class="fa fa-trash"></i></a>
                  </div> -->
                </td>
              </tr>
              <tr *ngIf="item?.isDetailsOpen">
                <td colspan="11"  style="background-color: #ffffff;border: 1px solid #eceeee;">
                  <div class="row" class="row">
                    <div class="col-md-12 col-xs-12 col-sm-12">
                      <table class="table table-bordered table-condensed">
                        <thead>
                          <tr class="custom-table-head">
                            <th style="width: 2%">#</th>
                            <th>Magasin</th>
                            <th>Nom medicaments</th>
                            <!-- <th>Quantité demandé</th> -->
                            <th>Quantité livrée</th>
                          </tr>
                        </thead>
        
                        <tbody>
                          <tr *ngFor="let item of itemsMedicament;let indice2 = index">
                            <td class="txt-align-center"> {{ indice2 + 1 }} </td>
                            <td> {{ item?.magasinLibelle }} </td>
                            <td> {{ item?.nomCommercialMedicament }} </td>
                            <td> {{ ( (item?.quantiteDemande || item?.quantite) | moneyFormat) || 0 }} </td>
                          </tr>
                        </tbody>
        
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
        
            <tfoot>
              <tr *ngIf="items && items.length > 0">
                <td colspan="11">
                  <div class="row pagination-part">
                    <div class="col-md-9" style="text-align: center">
                      <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage"
                        [totalItems]="totalItems" [(ngModel)]="currentPage" class="pagination-sm"
                        previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                        (pageChanged)="pageChanged($event)"></pagination>
                    </div>
        
                    <div class="col-md-3">
                        <div class="paginator-per-page">
          
                          <app-element-by-page-paginator (paginationChange)="changePaginationSize($event)"
                            [defaultItemPerPage]="itemsPerPage">
                          </app-element-by-page-paginator>
          
                          <span class="span_total_entree pull-right">Total : {{
                            totalItems
                            }}</span>
          
                        </div>
                      </div>
                  </div>
                </td>
              </tr>
        
              <tr *ngIf="!items || items.length == 0">
                <td colspan="12">
                  <div class="col-md-12 txt-align-center">
                    Aucune données disponible
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
    </div>

      

