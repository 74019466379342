import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import * as _ from "lodash";
import { enumTailleFichier } from 'src/app/core/enumerator/enumerator';

@Component({
  selector: 'app-add-document-fiche-engagement',
  templateUrl: './add-document-fiche-engagement.component.html',
  styleUrls: ['./add-document-fiche-engagement.component.scss']
})
export class AddDocumentFicheEngagementComponent implements OnInit {

  imageDisplay : any = {};
  currentItemImage : any = {};
  itemImageATraiter : any = {};
  ListeImagesUploader : Array<any> = [];
  enumTailleFile : any = {};
  isOpenPrevisualisation : boolean = false;
  isBigger : boolean = false;


  constructor(private utilities: UtilitiesService) { 
    this.enumTailleFile = enumTailleFichier;

    console.log(" this.enumTailleFile ",this.enumTailleFile);
    
  }
  
  @Output() addDocument: EventEmitter<any> = new EventEmitter();
  @Input() isRefresh;
  @Input() isReadOnly;
  @Input() defaultDocuments : Array<any> = [];

  extensionValide=this.utilities.getExtensionsImageRadio();

  sendDocumentMainPage() {
    let listTToEmit = [];

    console.log(" this.ListeImagesUploader ",this.ListeImagesUploader);
    
    this.ListeImagesUploader.forEach(element => {
       let  itemImg = {...element};
      //  itemImg.fullBase64 = null;
      //  itemImg.fileName = null;

       listTToEmit.push(itemImg);

    });
    this.addDocument.emit(listTToEmit); 
  }


  previsualisationImage(isOpen) {
    //Recuperé la div details
    let divDetails = document.getElementById("document-zoom");

    this.isOpenPrevisualisation = isOpen;
 
    if (divDetails && isOpen) {
      divDetails.classList.add("open-details");
    } else {
      // Nous fermons notre fenetre de details
      divDetails.classList.remove("open-details");
    }
  }


  uploadFile(event: any) {
    this.itemImageATraiter.messageError = null;
    let item = {
      fileName: null,
      fileBase64: null
    };
    let reader = new FileReader();

    let file = event.target.files[0];

    console.log(" file ",file);

    let valeurTailleMax = enumTailleFichier?.valeur * 1000000 ;

    console.log(" valeurTailleMax ",valeurTailleMax);
    
    if (file && (file.size > valeurTailleMax)) {

      this.utilities.showNotification(
        "snackbar-danger",
        this.utilities.formatMsgServeur('Veuillez vérifier la taille du fichier.'),
        "bottom",
        "center"
      );
      event.target.value = null;
      return;
    }

    item.fileName = file.name;
    let Tabextension = file.name.split('.');
    let extension = Tabextension[Tabextension.length - 1];

    //verifier si l'extension est accepter
    const listeExtensionImagesValide = this.utilities.getExtensionsImageRadio();

    if (!_.find(listeExtensionImagesValide, { 'extension': extension.toLowerCase() })) {
        // this.itemImageATraiter.messageError = "Veuillez vérifier l'extension du fichier.";

        this.utilities.showNotification(
          "snackbar-danger",
          this.utilities.formatMsgServeur("Veuillez vérifier l'extension du fichier."),
          "bottom",
          "center"
        );

        event.target.value = null;
        return;
    }

    reader.onload = (readerEvent) => {

      let data = (readerEvent.target as any).result;
    
      item.fileBase64 = data.split(',')[1];
      this.currentItemImage = {
        fileBase64: item.fileBase64,
        nomFichier: item.fileName,
        extension: extension,
        fullBase64 : data,
        urlFile : data,
        fileName:item.fileName,
        typeDocument:extension?.toLowerCase() == "pdf" ? "PDF" : "IMG"
      };

      this.ListeImagesUploader.push(this.currentItemImage);
      this.sendDocumentMainPage();
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  gotoUploadFile()
  {
    let inputFile = document.getElementById("id-joindre-file");
    if(inputFile)
    {
      inputFile.click();
    }
  }

  deleteImg(indice)
  {
    this.ListeImagesUploader.splice(indice,1);
    this.sendDocumentMainPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['isRefresh'] && changes['isRefresh'].currentValue)
    {
      this.ListeImagesUploader = [];
    }

    if(changes['defaultDocuments'] && changes['defaultDocuments'].currentValue)
    {
      this.ListeImagesUploader = [];
      this.ListeImagesUploader = changes['defaultDocuments'].currentValue;
    }
  }
  
  openFile(file){
    
  }

  ngOnInit() {
  }


}
