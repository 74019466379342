import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import * as moment from 'moment';

@Component({
  selector: 'app-historique-seance-dialyse',
  templateUrl: './historique-seance-dialyse.component.html',
  styleUrls: ['./historique-seance-dialyse.component.scss']
})
export class HistoriqueSeanceDialyseComponent implements OnInit {

  listItems: Array<any> = [];
  items: Array<any> = [];
  itemToSave: any = {};
  modalRef: BsModalRef;
  user: any = {};
  itemToSearch: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  busyGet: Subscription;
  busySave: Subscription;
  loading: boolean = false;
  endPoint : string = 'accueil/';
  dateActuelle = new Date();
  isAbsenceView : boolean;

  @Input() isTraiterFiltre : boolean;

  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
    this.itemToSearch = {
      dialyseDisponibiliteLibelle : moment().format('DD/MM/YYYY').toString(),
      dateDispo : new Date()
    }
  }

  changePaginationSize($event) {
    if($event) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt($event);
    }
    this.getItems();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page ;
    this.getItems();
  }

  openModal(data: any, template: TemplateRef<any>,isAccueilDialyse?) {

    let config = {backdrop: true, ignoreBackdropClick: true};

    this.itemToSave = {};
    this.isAbsenceView = false;

    if (data) {
      // Lorsque nous sommes en modification
      this.itemToSave = Object.assign({}, data);

      if(this.itemToSave.motifSeance) {
        this.isAbsenceView = true;
      }
    }

    this.modalRef = this.modalService.show(template,Object.assign({},config, { class: !isAccueilDialyse ? 'modal-lg modal-width-75' : 'modal-lg modal-width-80' }));
  }

  fermertureFormulaire(){
    this.modalRef.hide();
    this.getItems();
  }

  getItems($event ?) {

    if($event) {
      console.log(" $event ",$event);
      this.itemToSearch.dateDispo = $event;

      // Verifie si c'est une data à venir
      // En ce moment on ne peut pas enregistrer d'accueil
      let diffMoment = moment(new Date()).diff(moment($event), 'days') ;

      this.itemToSearch.differenceDate = diffMoment;

      if(diffMoment != 0) {
        this.itemToSearch.canCreateAccueil = false;
      }else {
        this.itemToSearch.canCreateAccueil = true;
      }
    }

    let request = {
      user: this.user.id, 
      data: {
        // beneficiaireNom: this.itemToSearch.libelle ? this.itemToSearch.libelle : null
        isTraite : this.isTraiterFiltre || null,
        dialyseDisponibiliteLibelle : this.itemToSearch.dateDispo ? moment(this.itemToSearch.dateDispo).format('DD/MM/YYYY').toString() : null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage
    }

    this.busyGet = this.restClient.post('dialyseProgrammeSeance/getByCriteria', request)
      .subscribe(
        res => {

          console.log(" resultat ",res);
          
          if (res && res['items']) {
            this.items = res['items'];
            this.totalItems = res['count'];
          }
          else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        err => {
        }
      );
  }

  getMontantTotalAssurance(items){
    let montantTotal = 0;
    if(items && items.length > 0){
       montantTotal = _.sumBy(items, (o)=> { return parseFloat(o.partAssurance); });
    }

    return montantTotal;  
  }

  getExactlyNumberRow(page,index)
  {
      let num = index +1;
      if(page>1)
      {
          num = ((page - 1) * 10) + (index+1);
      }
      return num;
  }
  
  confirmSaveItem(item) {

    if (!item || !item.motifSeance) {
      this.utilities.showNotification("snackbar-danger", "Veuillez renseigner le motif d'absence !","bottom","center");
      return;
    }

    Swal.fire({
      title: "Absence de séance",
      text: "Vous êtes sur le point d'enregistrer une absence de dialyse. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        let obj = {
          motifSeance : item.motifSeance,
          id : this.itemToSave?.id
        }
        this.saveItem(obj);
      }
    });
  }


  saveItem(item) {

    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);
    itemAEnregistrer.isOpened = !!item.isOpened
    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer
      ]
    }
    // console.log('request',request);

    this.busySave = this.restClient.post('dialyseProgrammeSeance/update', request)
      .subscribe(
        res => {

          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");

              this.getItems();
              this.modalRef.hide();
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  ngOnInit() {
    this.getItems();
  }


}
