<ng-container>
  <div class="row" [ngBusy]="{
      busy: busyGet,
      message: 'Veuillez patienter ...',
      backdrop: false
    }">
    <div class="col-10 mb-1 search-part">
      <div class="row">
        <div class="col-md-2 col-sm-12 mb-2">
          <label>Matricule : </label>
          <input class="form-control custom-input" placeholder="Matricule" autocomplete="off"
            [(ngModel)]="currentPatient.beneficiaireMatricule" name="matriculeBeneficiaire" [disabled]="true"
            type="text" />
        </div>

        <div class="col-md-4 col-sm-12">
          <label>Nom & prénoms : </label>
          <input class="form-control custom-input" placeholder="Nom et prénoms" autocomplete="off"
            [(ngModel)]="currentPatient.identiteBeneficiaire" name="identiteBeneficiaire" [disabled]="true"
            type="text" />
        </div>

        <div class="col-md-1 col-sm-12">
          <label>Genre: </label>
          <input class="form-control custom-input" placeholder="Genre" autocomplete="off"
            [(ngModel)]="currentPatient.beneficiaireSexe" name="sexe" [disabled]="true" type="text" />
        </div>

        <div class="col-md-2 col-sm-12">
          <label>Age : </label>
          <input class="form-control custom-input" placeholder="Age" autocomplete="off"
            [(ngModel)]="currentPatient.beneficiaireAge" name="beneficiaireAge" [disabled]="true" type="text" />
        </div>

        <div class="col-md-2 col-sm-12">
          <label>Catégorie client : </label>
          <input class="form-control custom-input" placeholder="Catégorie" autocomplete="off"
            [(ngModel)]="currentPatient.categorie" name="categorie" [disabled]="true" type="text" />
        </div>

        <div class="row">

          <div class="col-md-2 col-sm-12" *ngIf="hassNeedGroupSanguin">
            <label>Groupe sanguin : </label>
            <input class="form-control custom-input" placeholder="beneficiaireGroupeSanguin" autocomplete="off"
              [(ngModel)]="currentPatient.beneficiaireGroupeSanguin" name="beneficiaireGroupeSanguin" [disabled]="true" type="text" />
          </div>

          <div class="col-md-5 col-sm-12 table-responsive" *ngIf="withAssurances" style="margin-top: 10px">
            <table class="table table-hover table-striped  table-details mb-0">
              <thead>
                <tr class="custom-table-head">
                  <th>Assurance</th>
                  <th>Taux</th>
                  <th>N°Bon</th>
                  <th>N°Carte</th>
                </tr>
              </thead>
  
              <tbody>
                <tr *ngFor="let assur of currentPatient.assurances">
                  <td>{{ assur?.libelle || assur?.assuranceLibelle }}</td>
                  <td>{{ assur?.taux }}</td>
                  <td>{{ assur?.numeroBon }}</td>
                  <td>{{ assur?.numeroCarte }}</td>
                </tr>
                <tr *ngIf="
                    !currentPatient.assurances ||
                    currentPatient.assurances.length == 0
                  ">
                  <td colspan="4" class="txt-align-center">
                    Aucune données disponible
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

       
      </div>

      <div class="row" *ngIf="afficheContact">
        <div class="col-md-3">
          <label>Télephone : </label>
          <input class="form-control custom-input" placeholder="Télephone" autocomplete="off"
            [(ngModel)]="currentPatient.contact" name="contact" [disabled]="true" type="text" mask="00-00-00-00-00" />
        </div>
      </div>
    </div>

    <div class="col-md-2 col-sm-12" *ngIf="currentPatient && currentPatient?.id" style="display: flex">
      <!-- <img src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
        alt="Photo assuré" class="pull-right img-assure" *ngIf="!imgUrl" /> -->
        
        <img
          [src]="imgUrl" (error)="handleError()"
          alt="Photo assuré"
          class="pull-right img-assure"
          style="width: 150px;height: 150px;" *ngIf="imgUrl"
        />
    </div>
  </div>
</ng-container>