import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/service/auth.service";
import { RestClientService } from "src/app/core/service/rest-client.service";
import { UtilitiesService } from "src/app/core/service/utilities.service";
import Swal from "sweetalert2";
import * as _ from "lodash";
import * as moment from 'moment';

@Component({
  selector: "app-form-demande-hospitalisation",
  templateUrl: "./form-demande-hospitalisation.component.html",
  styleUrls: ["./form-demande-hospitalisation.component.scss"],
})
export class FormDemandeHospitalisationComponent implements OnInit {
  @Input() currentPatient: any;
  @Input() consultation: any;
  @Output() closeForm = new EventEmitter<any>();
  @Input() isHistoConsultation: any;
  listAssurances: Array<any> = [];
  itemToSave: any = {};
  busyGet: Subscription;
  listTypeAntecedent: Array<any> = [];
  listPathologies: Array<any> = [];
  listPathologiesToSave: Array<any> = [];
  listUniteFonctionnelles: Array<any> = [];
  listeModeAdmissions: Array<any> = [];
  listTypeHospitalisations: Array<any> = [];
  listeTypesAdminissions: Array<any> = [];

  user: any = {};
  loading: boolean;
  dateActuelle = new Date();

  constructor(
    private authService: AuthService,
    private restClient: RestClientService,
    private modalService: BsModalService,
    private utilities: UtilitiesService
  ) {
    this.user = this.authService.currentUserValue;
    // this.itemToSave.dateHospi = new Date()
  }

  closeFormulaire() {
    this.closeForm.emit(true);
  }

  getTypeAdminssion() {
    let request = {
      user: this.user.id,
      data: {},
    };

    this.busyGet = this.restClient
      .post("hospiTypeAdmission/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.listeTypesAdminissions = _.orderBy(
              res["items"],
              ["libelle"],
              ["asc"]
            );

            if (this.itemToSave?.typeAdmissionId) {
              this.itemToSave.typeAdminissionSelected = _.find(
                this.listeTypesAdminissions,
                (o) => {
                  return o.id == this.itemToSave?.typeAdmissionId;
                }
              );
            }
          } else {
            this.listeTypesAdminissions = [];
          }
        },
        (err) => {}
      );
  }

  getTypeHospitalisation() {
    let request = {
      user: this.user.id,
      data: {},
    };

    this.busyGet = this.restClient
      .post("typeHospitalisation/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.listTypeHospitalisations = _.orderBy(
              res["items"],
              ["libelle"],
              ["asc"]
            );

            if (this.itemToSave?.typeHospitalisationId) {
              this.itemToSave.typeHospitalisationSelected = _.find(
                this.listTypeHospitalisations,
                (o) => {
                  return o.id == this.itemToSave?.typeHospitalisationId;
                }
              );
            }
          } else {
            this.listTypeHospitalisations = [];
          }
        },
        (err) => {}
      );
  }

  getModeAdmission() {
    let request = {
      user: this.user.id,
      data: {},
    };

    this.busyGet = this.restClient
      .post("hospiModeAdmission/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.listeModeAdmissions = _.orderBy(
              res["items"],
              ["libelle"],
              ["asc"]
            );

            if (this.itemToSave?.modeAdmissionHospiId) {
              this.itemToSave.modeAdminssionSelected = _.find(
                this.listeModeAdmissions,
                (o) => {
                  return o.id == this.itemToSave?.modeAdmissionHospiId;
                }
              );
            }
          } else {
            this.listeModeAdmissions = [];
          }
        },
        (err) => {}
      );
  }

  getUniteFonctionnelle() {
    let request = {
      user: this.user.id,
      data: {
        estServiceMedical : true
      },
    };

    this.busyGet = this.restClient
      .post("adminUniteFonctionnelle/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.listUniteFonctionnelles = _.orderBy(
              res["items"],
              ["libelle"],
              ["asc"]
            );

            if (this.itemToSave?.uniteFoncId) {
              this.itemToSave.uniteFocntionnelleSelected = _.find(
                this.listUniteFonctionnelles,
                (o) => {
                  return o.id == this.itemToSave?.uniteFoncId;
                }
              );
            }
          } else {
            this.listUniteFonctionnelles = [];
          }
        },
        (err) => {}
      );
  }

  getDataDemandeHospi() {
    let request = {
      user: this.user.id,
      data: {
        consultationId: this.consultation?.id,
      },
      index : 0,
      size : 1
    };

    this.busyGet = this.restClient
      .post("hospiDemande/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.itemToSave = res["items"]?.length > 0 ? res["items"][0] : {};

            // Trasnformer la date d'hospi
            let dateSplit = this.itemToSave.dateHospi.split("/");

            this.itemToSave.dateHospi = new Date(dateSplit[2],dateSplit[1]-1,dateSplit[0]);

            if(this.itemToSave.heureDebutMo) {
              this.itemToSave.heureDebut = this.itemToSave.heureDebutMo?.replace(":","");
            }

            if(this.itemToSave.heureFinMo) {
              this.itemToSave.heureFin = this.itemToSave.heureFinMo?.replace(":","");
            }
           

            this.getData();
          } else {
            this.itemToSave = {};
            this.itemToSave.dateHospi = new Date()
            this.getData();
          }
        },
        (err) => {}
      );
  }

  confirmSaveItem(item) {

    let objToSave = Object.assign({}, item);
    console.log("objToSave: ",objToSave)
    if (!this.currentPatient?.id) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez sélectionner un patient !",
        "bottom",
        "center"
      );
      return;
    }

    if (!this.itemToSave.uniteFocntionnelleSelected) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez sélectionner une unité fonctionnelle !",
        "bottom",
        "center"
      );
      return;
    }

    if (!this.itemToSave.typeHospitalisationSelected) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez sélectionner un type d'hospitalisation!",
        "bottom",
        "center"
      );
      return;
    }

    if (!this.itemToSave.modeAdminssionSelected) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez sélectionner un mode d'admission !",
        "bottom",
        "center"
      );
      return;
    }

    if (!this.itemToSave.typeAdminissionSelected) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez sélectionner un type d'admission !",
        "bottom",
        "center"
      );
      return;
    }


    if (!this.itemToSave.causeHospi) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner la cause d'hospitalisation !",
        "bottom",
        "center"
      );
      return;
    }

    // Control dans le cas ou c'est une mise en observation les different cham de durée et heure sont obligatoire
    if (
      this.itemToSave.typeHospitalisationSelected &&
      this.itemToSave.typeHospitalisationSelected?.code == "MO"
    ) {
      if (!this.itemToSave.dureeMo) {
        this.utilities.showNotification(
          "snackbar-danger",
          "Veuillez renseigner la durée !",
          "bottom",
          "center"
        );
        return;
      }

      if (!this.itemToSave.heureDebut) {
        this.utilities.showNotification(
          "snackbar-danger",
          "Veuillez renseigner l'heure de début !",
          "bottom",
          "center"
        );
        return;
      }

      if (!this.itemToSave.heureFin) {
        this.utilities.showNotification(
          "snackbar-danger",
          "Veuillez renseigner l'heure de fin !",
          "bottom",
          "center"
        );
        return;
      }


      if (parseFloat(this.itemToSave.dureeMo) > 24) {
        this.utilities.showNotification(
          "snackbar-danger",
          "Veuillez vérifier la durée de la mise en observation ( Elle ne doit pas excéder 24H ) !",
          "bottom",
          "center"
        );
        return;
      }

      // if (objToSave.heureDebut) {
      //   objToSave.heureDebutMo =
      //     (objToSave.heureDebut.substr(0, 2) || "00") +
      //     ":" +
      //     (objToSave.heureDebut.substr(2, 2) || "00") 
      // }

      // if (objToSave.heureFin) {
      //   objToSave.heureFinMo =
      //     (objToSave.heureFin.substr(0, 2) || "00") +
      //     ":" +
      //     (objToSave.heureFin.substr(2, 2) || "00") 
      // }
      
    }

    // On fait les controls dans le cas ou il s'agit d'une hospitalisation
    if (
      this.itemToSave.typeHospitalisationSelected &&
      this.itemToSave.typeHospitalisationSelected?.code == "HP"
    ) {
      if (!this.itemToSave.nbreJourHospi) {
        this.utilities.showNotification(
          "snackbar-danger",
          "Veuillez renseigner le nombre de jour !",
          "bottom",
          "center"
        );
        return;
      }
    }

    // Formatage de la date d'hospi
    if (!this.itemToSave.dateHospi) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner la date d'hospitalisation !",
        "bottom",
        "center"
      );
      return;
    }else {
      objToSave.dateHospi = moment(this.itemToSave.dateHospi).format("DD/MM/YYYY");
    }


    objToSave.medecinId = this.user?.id;
    objToSave.patientId = this.currentPatient?.patientId;
    objToSave.consultationId = this.consultation.id;
    objToSave.uniteFoncId = this.itemToSave?.uniteFocntionnelleSelected?.id;
    objToSave.typeHospitalisationId = this.itemToSave?.typeHospitalisationSelected?.id;
    objToSave.modeAdmissionHospiId = this.itemToSave?.modeAdminssionSelected?.id;
    objToSave.typeAdmissionId = this.itemToSave?.typeAdminissionSelected?.id;
    
    objToSave.typeHospitalisationSelected = null;
    objToSave.uniteFocntionnelleSelected = null;
    objToSave.modeAdminssionSelected = null;
    objToSave.typeAdminissionSelected = null;
    objToSave.datasAssurance = null;
    objToSave.heureDebutMo = item.heureDebut
    objToSave.heureFinMo = item.heureFin
    if(objToSave.id){
      if(item.heureDebut && !item.heureDebut.includes(":"))
        objToSave.heureDebutMo = item.heureDebut.substring(0, 2) + ":" + item.heureDebut.substring(2);
      if(item.heureDebut && !item.heureFin.includes(":"))
        objToSave.heureFinMo = item.heureFin.substring(0, 2) + ":" + item.heureFin.substring(2); 
    }
    Swal.fire({
      title: "Demande d'hospi",
      text: objToSave?.id
        ? "Vous êtes sur le point de modifier une demande. Voulez-vous poursuivre cette action ?"
        : "Vous êtes sur le point d'enregistrer une demande. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.saveItem(objToSave);
      }
    });
  }

  saveItem(item) {
    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);
 

    var request = {
      user: this.user.id,
      datas: [itemAEnregistrer],
    };

    this.busyGet = this.restClient
      .post(
        "hospiDemande/" + (itemAEnregistrer.id ? "update" : "create"),
        request
      )
      .subscribe(
        (res) => {
          console.log("resul", res);
          this.loading = false;

          if (!res["hasError"]) {
            if (res["items"] && res["items"].length > 0) {
              this.utilities.showNotification(
                "snackbar-success",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
              this.closeFormulaire();
            }
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
          this.loading = false;
        }
      );
  }

  getDureeMiseEnObservation() {
    console.log('this.itemToSave.heureDebut: ',this.itemToSave.heureDebut)
    // console.log('this.itemToSave.heureFin: ',this.itemToSave.heureFin)

    let dateHeureDebut : any;
    let dateHeureFin : any;
    // if(this.itemToSave.heureDebutMo) {
    //   dateHeureDebut = moment(new Date(2022,1,1,this.itemToSave.heureDebutMo.substr(0, 2),this.itemToSave.heureDebutMo.substr(2, 2)))
    // }

    // if(this.itemToSave.heureFinMo) {
    //   dateHeureFin = moment(new Date(2022,1,1,this.itemToSave.heureFinMo.substr(0, 2),this.itemToSave.heureFinMo.substr(2, 2)))
    // }

    if(this.itemToSave.heureDebut && !this.itemToSave.heureDebut.includes(":"))
      this.itemToSave.heureDebut = this.itemToSave.heureDebut.substring(0, 2) + ":" + this.itemToSave.heureDebut.substring(2);
    if(this.itemToSave.heureFin && !this.itemToSave.heureFin.includes(":"))
      this.itemToSave.heureFin = this.itemToSave.heureFin.substring(0, 2) + ":" + this.itemToSave.heureFin.substring(2);

    
    if(this.itemToSave.heureDebut) {
      let h = Number((this.itemToSave.heureDebut+'').split(':')[0])
      let m = Number((this.itemToSave.heureDebut+'').split(':')[1])
      dateHeureDebut = moment(new Date(2022,1,1,h,m))
    }

    if(this.itemToSave.heureFin) {
      let h = Number((this.itemToSave.heureFin+'').split(':')[0])
      let m = Number((this.itemToSave.heureFin+'').split(':')[1])
      dateHeureFin = moment(new Date(2022,1,1,h,m))
    }
    
    if(dateHeureDebut && dateHeureFin) {
      let difference = dateHeureFin.diff(dateHeureDebut, "minutes") ;
      this.itemToSave.dureeMo = (difference / 60).toFixed(2);
    }else {
      this.itemToSave.dureeMo = 0;
    }
   
  }

  getData() {
    this.getTypeAdminssion();
    this.getModeAdmission();
    this.getTypeHospitalisation();
    this.getUniteFonctionnelle();
  }

  ngOnInit(): void {
    // Recuperer les anciennes valeurs déja enregistré
    this.getDataDemandeHospi();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["currentPatient"] && changes["currentPatient"].currentValue) {
      this.currentPatient = changes["currentPatient"].currentValue;
      this.listAssurances = this.currentPatient.assurances;
      this.currentPatient.medecin = this.currentPatient?.userNom+' '+this.currentPatient?.userPrenom;
      // console.log(" this.currentPatient ",this.currentPatient);
      
    }

    if (changes["consultation"] && changes["consultation"].currentValue) {
      this.consultation = {...changes["consultation"].currentValue};
      this.consultation.isClotured = false;
    }

  }
}
